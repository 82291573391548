/**
 * プログラムモジュール
 * 非同期通信が重い場合のプログレスバーの表示
 * @module store/modules/progress
 */

import NProgress from 'nprogress'

// initial state
const state = {
    isInProgress: false
}

// getters
const getters = {
    isInProgress: (state) => state.isInProgress
}

// actions
const actions = {
    startProgress({commit}){
        commit('start')
    },
    finishProgress({commit}){
        commit('done')
    }
}

const mutations = {
    start(state) {
        state.isInProgress = true
        NProgress.start()
        NProgress.set(0.6)
    },
    done(state) {
        NProgress.done()
        state.isInProgress = false
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
