const routes = [
    {
        path: '/admin/brand_names',
        component: () => import(/* webpackChunkName: "group-admin-brand-names" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-admin-brand-names" */ '@/views/AdminBrandNameList.vue'),
            },
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "group-admin-brand-names" */ '@/views/AdminBrandNameEdit.vue'),
                meta: {type:'create'}
            },
            {
                path: ':id/edit',
                component: () => import(/* webpackChunkName: "group-admin-brand-names" */ '@/views/AdminBrandNameEdit.vue'),
                meta: {type:'edit'}
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-brand-names" */ '@/views/AdminBrandNameDelete.vue'),
            },
        ]
    }
]

export default routes