<template>
    <footer>
        <ul>
            <li><a href="https://www.hyoukakyoukai.or.jp/sitemap.html">サイトマップ</a></li>
            <li><a href="https://www.hyoukakyoukai.or.jp/contact.html">お問合わせ</a></li>
            <li><a href="https://www.hyoukakyoukai.or.jp/news/index.html">新着情報</a></li>
            <li><a href="https://www.hyoukakyoukai.or.jp/topics/index.html">トピックス</a></li>
            <li><a href="https://www.hyoukakyoukai.or.jp/link.html">リンク集</a></li>        
        </ul>
        <small class="str_1gyo">Copyright © 2002-{{ year }}. All rights reserved. 掲載内容の無断転載、商用利用を禁じます。</small>
    </footer>
</template>

<script>
/**
 * 共通フッター
 */
export default {
  name: 'AppFooter',
  computed:{
      year(){
          const date = new Date()
          return date.getFullYear()
      }
  }
}
</script>

<style scoped>
footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    height: fit-content;
    height: -moz-fit-content;
    padding: 0.5em 0;
}
footer ul {
    min-width: fit-content;
    margin: auto 1rem auto 0;
    display: inline-block;
    padding: 0;
}
footer ul li{
    display: inline;
    padding: 0.25em 1em;
    line-height: 1em;
    border-right: 1px solid #111;
    white-space: nowrap;
}
footer ul li:last-of-type{
    border-right: none;
}
footer ul li a:link{
    opacity: 0.8;
}
footer ul li a:link:hover{
    opacity: 1;
}
footer small{
    display: inline-block;
    margin: 0.25rem 1rem;
}
</style>
