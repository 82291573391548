import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions'

const cacheConfig = {
    enabledByDefault: false, 
    cacheFlag: 'useCache'
}

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
    headers: {
        'Cache-Control': 'no-cache'
    },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, cacheConfig)
})

export default httpClient
