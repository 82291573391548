const routes = [
    {
        path: '/admin/shared_passwords',
        component: () => import(/* webpackChunkName: "group-admin-shared-passwords" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-admin-shared-passwords" */ '@/views/AdminSharedPasswordList.vue'),
            },
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "group-admin-shared-passwords" */ '@/views/AdminSharedPasswordEdit.vue'),
                meta: {type:'create'}
            },
            {
                path: ':id/edit',
                component: () => import(/* webpackChunkName: "group-admin-shared-passwords" */ '@/views/AdminSharedPasswordEdit.vue'),
                meta: {type:'edit'}
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-shared-passwords" */ '@/views/AdminSharedPasswordDelete.vue'),
            },
        ]
    }
]

export default routes