import Vue from 'vue'
import axios from '@/api'
import { isSet } from '@/util'

/**
 * 認証モジュール
 * @module store/modules/login
 */

// initial state
const state = {
    /**
     * ログインユーザ情報
     */
    user:[],
    /**
     * ログイン時メッセージ
     * @example
     * 'ログインに成功しました。'
     * @example
     * 'ログインに失敗しました。メールアドレスまたはパスワードが正しくありません。'
     */
    message: '',
    /**
     * ログインエラー情報
     * @example
     *  {
     *      "password": [
     *          "The パスワード field is required."
     *      ],
     *      "type": [
     *          "The selected 種別 is invalid."
     *      ]
     *  }
     */
    errors: {},
    // ログイン時に入力するメールアドレスとパスワード
    email:'',
    password:'',
    password_confirm:'',
    // ログイン中ユーザの所属機関とその機関に所属するユーザの一覧
    organization:{},
    users:[]
}

// getters
const getters = {
    /**
     * ログイン時メッセージ 
     * @returns {String}
     */
    message(state) {
        return state.message
    },
    /**
     * エラー情報
     */
    errors(state) {
        return state.errors
    },
    /**
     * ユーザ情報
     * @returns {Object}
     */
    user(state) {
        if (state.user.length > 0) {
            const user = state.user[0]
            return user
        } else {
            return undefined
        }
    },
    /**
     * ログインページで必要な情報をまとめて返す
     * @param {Object} state 
     */
    authInfo(state) {
        return {
            email:state.email,
            password:state.password,
            message:state.message
        }
    },
    /**
     * ログイン中のユーザに紐づく事業者を返す
     * @param {Object} state 
     */
    organization(state) {
        return state.organization
    },
    /**
     * ログイン中のユーザに紐づく事業者に紐づく全ユーザを返す
     * @param {Object} state 
     */
    users(state) {
        return state.users
    },
    email(state) {
        return {
            email:state.email
        }
    },
    reset(state) {
        return {
            email:state.email,
            password:state.password,
            password_confirm:state.password_confirm,
        }
    }
}

const actions ={
    /**
     * ログイン
     * @param {String} type house|building
     * @returns {Object|Boolean} 成功した場合はユーザ情報,失敗時はfalse
     */
    login({state, commit}, type){
        /*
         * 認証情報の送信
         */
        return axios.post('/api/login', {
                email:state.email,
                password: state.password,
                type
            })
                .then(() => {
                    return axios.get('/api/login')
                }, reject => {
                    throw reject
                })
                .then(response => {
                    if (isSet(response.data) && response.data.users.length > 0) {
                        const user = response.data.users[0]
                        commit('update', {user:[user]})
                        return user
                    } else {
                        commit('update', {
                            user:[],
                            message:'',
                            errors:{},
                        })
                    }
                }, reject => {
                    if (reject.response.data.message) {
                        commit('update', {message:reject.response.data.message})
                    }
                    if (reject.response.data.errors) {
                        commit('update', {errors:reject.response.data.errors})
                    }
                })

    },
    /**
     * ログアウト
     */
    logout() {
        return axios.post('/api/logout')
    },
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    /**
     * ログイン中ユーザの所属機関を取得
     * @param {Object} state 
     * @param {Function} commit 
     */
    getOrganization({state, commit}) {
        return axios.get('/api/organizations/' + state.user[0].organization_id)
            .then(response => {
                commit('update', {organization:response.data.organizations[0]})
            })
    },
    /**
     * ログイン中ユーザと同じ機関に所属するユーザを取得
     * @param {Object} state 
     * @param {Function} commit 
     */
    getOrganizationUsers({state, commit}) {
        return axios.get('/api/organizations/' + state.user[0].organization_id + '/users')
            .then(response => {
                commit('update', {users:response.data.users})
            })
    },
    /**
     *  パスワードリセット用メールを送信する
     */
    sendResetLinkEmail({state}, type) {
        return axios.post('/api/password/email', {
            email:state.email,
            type
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    /**
     *  パスワードリセットを実行
     */
    resetPassword({state}, {type, token}) {
        return axios.post('/api/password/reset', {
            email:state.email,
            password:state.password,
            password_confirmation:state.password_confirm,
            token,
            type
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    }
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
