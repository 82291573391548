const routes = [
    {
        path: '/admin/information',
        component: () => import(/* webpackChunkName: "group-admin-information" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-admin-information" */ '@/views/AdminInformationList.vue'),
            },
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "group-admin-information" */ '@/views/AdminInformationEdit.vue'),
                meta:{type:'create'}
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "group-admin-information" */ '@/views/AdminInformationDetail.vue'),
                meta:{type:'detail'}
            },
            {
                path: ':id/edit',
                component: () => import(/* webpackChunkName: "group-admin-information" */ '@/views/AdminInformationEdit.vue'),
                meta:{type:'edit'}
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-information" */ '@/views/AdminInformationDetail.vue'),
                meta:{type:'delete'}
            },
        ]
    }
]

export default routes