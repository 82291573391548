<template>
    <button
        @click="onclick"
        @mouseover="onmouseover"
        @mouseleave="onmouseleave"
        :class="{updated: is_update}"
        :disabled="disabled"
        class="el_button"
        v-html="name"
    />
</template>
<script>
export default {
    name: 'AppButton',
    props:{
        /**
         * ボタン名
         * @type {String}
         */
        name: {
            type: String,
            required: true
        },
        is_update: {
            type: Boolean,
            default: false
        },
        /**
         * ボタンを無効化する場合はtrue
         */
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        onclick(event){
            this.$emit('click', event)
        },
        onmouseover(event){
            this.$emit('mouseover', event)
        },
        onmouseleave(event){
            this.$emit('mouseleave', event)
        },
    }
}
</script>
<style lang="scss" scoped>
    button{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background-color:#4A6533;
        color:#FFF;
        height: 36px;
        line-height:1rem;
        padding: 0.5rem 2em;
        margin:0.5rem;
        border: none;
        font-weight: 600;
        text-align: center;
        border-radius: 18px;
        cursor: pointer;
    }
    button:not(.inline):hover{
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    }
    button.hover_color__active:hover{
        background-color: #3D9A46;
    }
    button.hover_scale:hover{
        transform: scale(1.1);
        box-shadow: 0 1px 1px 2px rgba(0,0,0, .1);
        font-weight: bolder;
    }
    _:-ms-lang(x)::-ms-backdrop, button{
        padding: 0.6em 2rem 0.4rem
    }
    /* 色の変更 */
    button.cancel{
        color: #5c5c5c;
        background-color: #efefef;
        border: 2px solid #5c5c5c;
    }
    button.maincolor,
    button.jutaku_color{
        background-color: #4A6533;
    }
    button.delete,
    button.caution{
        background-color: #BE4316;
    }
    
    button.delete:hover,
    button.caution:hover,
    button.delete.hover_color__delete:hover,
    button.caution.hover_color__caution:hover{
        background-color: #9e2a00;
    }
    button.uq_btnCopy:hover{
        color: #ccc;
    }
    /* 線付きボタン */
    button.outlined{
        color: #4A6533;
        background-color: white;
        border: 2px #C0D6DD solid;
    }
    button.outlined:hover{
        background-color: #4A6533;
        color: white;
        border: 2px #4A6533 solid;
    }
    /* テキストのみのボタン */
    button.textonly{
        height: auto;
        padding: 0;
        background-color: transparent;
        color: #4A6533;
    }
    button.textonly:hover{
        box-shadow:none;
        color: #3D9A46;
    }
    /* Disabled時 */
    button:disabled {
        opacity: 0.5;
        cursor: auto;
    }
    button:disabled:hover{
        box-shadow: none;
    }
    /* サイズの変更 */
    button.inline{
        height: 24px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0.25em 1em;
        font-weight: bolder;
        border-radius: 13px;
    }
    button.big{
        height: 50px;
        font-weight: bolder;
        border-radius: 25px;
    }
    button.wd_60px{
        width: 60px;
    }
    button.wd_80px{
        width: 80px;
        white-space: nowrap;
    }
    button.wd_100px{
        width: 100px;
        white-space: nowrap;
    }
    button.wd_200px{
        width: 200px;
        white-space: nowrap;
    }
    /* レイアウトの変更 */
    button.btn_center{
        display: block;
        margin: 0.5rem auto;
    }
    
</style>