<template>
    <div class="bl_breadcrumb">
        <span
            @click="top"
            ref="jest-organizations-breadcrumb-top"
        >
            トップページ
        </span>
        <span class="uq_nohoveraction">
            /
        </span>
        <span
            @click="primary"
            ref="jest-organizations-breadcrumb-back"
        >
            カテゴリー⼀覧
        </span>
        <span 
            v-if="breadcrumbCategory"
            class="uq_nohoveraction">
            /
        </span>
        <span
            v-if="breadcrumbCategory"
            @click="secondary"
            ref="jest-organizations-breadcrumb-category-name">{{breadcrumbCategory}}
        </span>
        <span 
            v-if="breadcrumbCategory"
            class="uq_nohoveraction">
            /
        </span>
        <span
            v-if="breadcrumbItem"
            ref="">{{breadcrumbItem}}
        </span>
    </div>
</template>
<script>
export default {
    name: 'Breadcrumb',
    props:{
        isHouse: {
            type: Boolean,
            required: true
        },
        isVisibleBreadcrumb:{
            type: Boolean,
            required: false,
        },
        breadcrumbCategory:{
            type:String,
            required: false
        },
        breadcrumbItem:{
            type:String,
            required: false
        }
    },
    methods:{
        top(){
            if (this.isHouse) {
                this.$router.push('/house').catch(() => {})
            } else {
                this.$router.push('/building').catch(() => {})
            }
        },
        primary(){
            if (this.isHouse) {
                this.$router.push('/house/links').catch(() => {})
            } else {
                this.$router.push('/building/links').catch(() => {})
            }
        },
        secondary(){
            if (this.breadcrumbCategory == 'コージェネレーション機器一覧') {
                this.$router.push('/cogenerations').catch(() => {})
            } else if (this.breadcrumbCategory == 'ハイブリッド給湯機一覧') {
                this.$router.push('/hybrid_water_heaters').catch(() => {})
            } else if (this.breadcrumbCategory == '電気ヒートポンプ給湯機一覧') {
                this.$router.push('/electric_heat_pumps').catch(() => {})
            } else if (this.breadcrumbCategory == 'ソーラーシステム機器一覧') {
                this.$router.push('/solar_systems').catch(() => {})
            } else if (this.breadcrumbCategory == '太陽熱温水器一覧') {
                this.$router.push('/solar_water_heaters').catch(() => {})
            } else {
                this.primary()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.bl_breadcrumb{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(85% - 34px);
    min-width: 850px;
    max-width: 1280px;
    padding: 5px 0 5px 32px;
    background-color: #F1F4EC;
    font-size: 12px;
    line-height: 12px;
    position: absolute;
    top: 100px;
    z-index: 10;
}
.bl_breadcrumb>span:not(.uq_nohoveraction){
    cursor: pointer;
}
</style>