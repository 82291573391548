const routes = [
    {
        path: '/solar_water_heaters',
        component: () => import(/* webpackChunkName: "group-solar-water-heaters" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-solar-water-heaters" */ '@/views/PublicSolarWaterHeaters.vue'),
                meta: { noGuard: true }
            },
            {
                path:':id',
                component: () => import(/* webpackChunkName: "group-solar-water-heaters" */ '@/views/SolarWaterHeatersDetail.vue'),
                meta: {
                    noGuard: true,
                    type:'detail',
                }
            },
        ]
    }
]

export default routes