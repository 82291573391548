import store from '@/store'

const routes = [
    {
        path: '/organization/register',
        component: () => import(/* webpackChunkName: "group-organization-register" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-organization-register" */ '@/views/OrganizationRegister.vue'),
                meta: { noGuard: true },
                beforeEnter: (to, from, next) => {
                    if (store.getters['status/accepted']) {
                        next()
                    } else {
                        next('/organization/guide')
                    }
                },
            },
            {
                path: 'confirm',
                component: () => import(/* webpackChunkName: "group-organization-register" */ '@/views/OrganizationRegisterConfirm.vue'),
                beforeEnter: (to, from, next) => {
                    if (store.getters['status/accepted']) {
                        next()
                    } else {
                        next('/organization/guide')
                    }
                },
                meta: { noGuard: true }
            },
            {
                path: 'complete',
                component: () => import(/* webpackChunkName: "group-organization-register" */ '@/views/OrganizationRegisterComplete.vue'),
                beforeEnter: (to, from, next) => {
                    if (store.getters['status/accepted']) {
                        next()
                    } else {
                        next('/organization/guide')
                    }
                },
                meta: { noGuard: true }
            },
        ]
    }
]

export default routes