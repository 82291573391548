<template>
    <p>{{innerContent}}</p>
</template>

<script>
/**
 * pタグの替わり
 * スロットに指定されたコンテンツが存在する場合には、
 * prefix, suffixプロパティの値をレンダリングします。
 *
 * [使用例]
 * <app-paragraph prefx="接頭語" suffix="接尾語">
 *     ふつうのPタグと同じように書きます
 * </app-paragraph>
 */
export default {
    name: 'AppParagraph',
    props: {
        /**
         * 接頭語
         */
        prefix: {
            type: String,
            default: undefined
        },
        /**
         * 接尾語
         */
        suffix: {
            type: String,
            default: undefined
        },
        /**
         * スロット内のコンテンツが数値かつ正の数の場合のみ表示する場合はtrue
         */
        positiveOnly: {
            type: Boolean,
            default: false
        }
    },
    computed:{
        innerContent() {
            //複数の子要素がある場合は無視、子要素を取得
            const content = this.hasDefault ? this.$slots.default[0].text : ''

            //表示条件の確認
            if (this.hasContent(content) && (this.positiveOnly == false || this.isPositive(content)))
            {
                let body = (this.prefix || '') + content.trim() + (this.suffix || '')
                return body
            } else {
                return ''
            }
        },
        hasDefault() {
            return this.$slots.default && this.$slots.default[0]
        }
    },
    methods:{
        hasContent(content){
            //コンテンツの有無確認
            if (content == null || content == undefined) return false
            if (content.trim() == '') return false

            return true
        },
        isPositive(number){
            if (isNaN(number)) {
                return false
            } else {
                return parseFloat(number) > 0
            }
        }
    }
}
</script>