<template>
    <li>
        <label :class="{checked:(value === vmodelValue)}">
            <input
                type="radio"
                :value="value"
                :checked="value === vmodelValue"
                :disabled="disabled_bool"
                @change="updateValue"
            >
            <span>
                <slot></slot>
            </span>
        </label>
    </li>
</template>

<!-- メモと呼び出し側の例
このAppSelectOptionコンポーネントはAppSelectコンポーネントの子としてのみ使います。
長文テキストのドロップダウンリストはapp-selectとapp-select-optionで改行可になります。短い文字列の場合は普通のselect/optionもどうぞ。
<app-select>
    <app-select-option
        value="a"
        v-model="vModelName"
    >
        aaaa
    </app-select-option>
</app-select>

-->
<script>
export default {
    name: 'AppSelectOption',
    data(){
        return {
            disabled_bool:this.disabled
        }
    },
    watch:{
        disabled(){
            this.disabled_bool = this.disabled
        }
    },
    model: {
        prop: 'vmodelValue',
        event: 'change'
    },
    props: {
        vmodelValue: {
            type: [String, Boolean, Number],
            //required: true
            required: false
        },
        value: {
            type: [String, Boolean, Number],
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        updateValue(){
            this.$emit('change', this.value)
        }
    }
}
</script>

<style scoped>
li{
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid #888;
    background-color: #777;
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: white;
	outline: none;
    cursor: pointer;
}
li:first-child{
    background-color:#555;
}
li:first-child label{
    color:#ccc;
}
label{
    display:grid;
    grid-template-columns: 16px 1fr;
    box-sizing: border-box;
    list-style-type: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 3px 16px;
    font-size: 14px;
    font-weight: bolder;
    line-height: 16px;
    color: white;
    white-space: nowrap;
    cursor: pointer;
}

label:before{
    content: " ";
    display: inline-block;
    font-size: 10px;
    line-height: 10px;
    width: 16px;
    height: 16px;
}
label span{
    padding-right: 8px;
}
label:hover{
    color: white;
    background-color: rgba(59, 153, 252, .7);
}
label.checked{
    color: white;
    background-color: rgba(59, 153, 252, .7);
}
label.checked:before{
    content: "\02714";
    display: inline-block;
    font-size: 10px;
    line-height: 10px;
    width: 16px;
    height: 16px;
}
input[type="radio"]{
    display: none;
}
</style>