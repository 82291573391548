import axios from '@/api'
import { cloneDeep } from 'lodash'

/**
 * 登録モジュール
 * @module store/modules/register
 */

import organization from '@/store/modules/register/organization'
import user from '@/store/modules/register/user'

//モジュールの登録
const modules = {
    organization,   //事業者編集モジュール
    user,           //ユーザ編集モジュール
}

// getters
const getters = {
    // 事業者データとユーザデータを結合した、新規登録用データを取得する
    getDataForRegist(state, getters) {
        // 一般ユーザで事業者情報を表示・編集した際のデータが残っている恐れがあるため削除する
        const organization = cloneDeep(getters['organization/getAll'])
        organization.cogeneration_manager = false
        organization.hybrid_water_heater_manager = false
        organization.electric_heat_pump_manager = false
        organization.solar_system_manager = false
        organization.solar_water_heater_manager = false
        const user = getters['user/getAll']
        return {
            organization,
            user
        }
    }
}

// actions
const actions = {
    // backendAPIに新規登録のための情報を送る
    register({commit, getters}, isHouse){
        const type = isHouse ? 1 : 2
        commit('organization/update', {type})
        return axios.post('/api/register', getters['getDataForRegist'])
    }
}

export default {
    namespaced: true,
    modules,
    getters,
    actions
}