import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueGtag from "vue-gtag"
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.config.productionTip = false

Vue.use(VueClipboard)

//vue-loading-overlay の設定
// デフォルト: 透明0.2
Vue.use(Loading, {
  opacity: 0.2
})

// Configuration VueGtag
Vue.use(VueGtag, {
  config: { id: 'G-VDK8WHPDJ0' }
})

/**
 * atomsのうち、共通で使用されるものをインポート
 */
import AppButton from '@/components/atoms/AppButton.vue'
import AppCheckBox from '@/components/atoms/AppCheckBox.vue'
import AppLabel from '@/components/atoms/AppLabel.vue'
import AppParagraph from '@/components/atoms/AppParagraph.vue'
import AppRadio from '@/components/atoms/AppRadio.vue'
import AppSelectOption from '@/components/atoms/AppSelectOption.vue'
import AppTextBox from '@/components/atoms/AppTextBox.vue'

/**
 * moleculesのうち、共通で使用されるものをインポート
 */
import AppSelect from '@/components/molecules/AppSelect.vue'
import AppText from '@/components/molecules/AppText.vue'

/**
 * 共通テンプレートをインポート
 */
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

/**
 * 上記のコンポーネントの登録
 */
Vue.component('app-button', AppButton)
Vue.component('app-checkbox', AppCheckBox)
Vue.component('app-label', AppLabel)
Vue.component('app-paragraph', AppParagraph)
Vue.component('app-radio', AppRadio)
Vue.component('app-select-option', AppSelectOption)
Vue.component('app-text-box', AppTextBox)
Vue.component('app-select', AppSelect)
Vue.component('app-text', AppText)
Vue.component('default-template', DefaultTemplate)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
