import adminBrandNames from '@/router/adminBrandNames.js'
import adminCogenerations from '@/router/adminCogenerations.js'
import adminElectricHeatPumps from '@/router/adminElectricHeatPumps.js'
import adminEvaluationOrganizations from '@/router/adminEvaluationOrganizations.js'
import adminHybridWaterHeaters from '@/router/adminHybridWaterHeaters.js'
import adminInformation from '@/router/adminInformation.js'
import adminLinks from '@/router/adminLinks.js'
import adminOrganizations from '@/router/adminOrganizations.js'
import adminSharedPasswords from '@/router/adminSharedPasswords.js'
import adminSolarSystems from '@/router/adminSolarSystems.js'
import adminSolarWaterHeaters from '@/router/adminSolarWaterHeaters.js'
import building from '@/router/building.js'
import cogenerations from '@/router/cogenerations.js'
import electricHeatPumps from '@/router/electricHeatPumps.js'
import house from '@/router/house.js'
import hybridWaterHeaters from '@/router/hybridWaterHeaters.js'
import organizationCogenerations from '@/router/organizationCogenerations.js'
import organizationElectricHeatPumps from '@/router/organizationElectricHeatPumps.js'
import organizationRegister from '@/router/organizationRegister.js'
import organizationOrganization from '@/router/organizationOrganization.js'
import organizationSolarSystems from '@/router/organizationSolarSystems.js'
import organizationSolarWaterHeaters from '@/router/organizationSolarWaterHeaters.js'
import organizationUsers from '@/router/organizationUsers.js'
import organizationLinks from '@/router/organizationLinks.js'
import organizationHybridWaterHeaters from '@/router/organizationHybridWaterHeaters.js'
import solarSystems from '@/router/solarSystems.js'
import solarWaterHeaters from '@/router/solarWaterHeaters.js'

let routes = [
  {
    path:'/organization/guide',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/OrganizationGuide.vue'),
    meta: { noGuard: true }
  },
  {
    path:'/password',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/PasswordEdit.vue'),
  },
  {
    path:'/login/house',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/AuthenticationLogin.vue'),
    meta: { noGuard: true }
  },
  {
    path:'/login/building',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/AuthenticationLogin.vue'),
    meta: { noGuard: true }
  },
  {
    path:'/logout',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/AuthenticationLogout.vue'),
    meta: { noGuard: true }
  },
  {
    path:'/password/email',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/AuthenticationPasswordEmail.vue'),
    meta: { noGuard: true }
  },
  {
    path:'/password/reset/:type/:token',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/AuthenticationPasswordReset.vue'),
    meta: { noGuard: true }
  },
  {
    path:'/top',
    beforeEnter(){
        window.location = 'https://www.hyoukakyoukai.or.jp/'
    },
    meta: { noGuard: true }
  }
]

routes = routes.concat(
  adminBrandNames,
  adminCogenerations,
  adminElectricHeatPumps,
  adminEvaluationOrganizations,
  adminHybridWaterHeaters,
  adminInformation,
  adminLinks,
  adminOrganizations,
  adminSharedPasswords,
  adminSolarSystems,
  adminSolarWaterHeaters,
  building,
  cogenerations,
  electricHeatPumps,
  house,
  hybridWaterHeaters,
  organizationCogenerations,
  organizationElectricHeatPumps,
  organizationLinks,
  organizationOrganization,
  organizationRegister,
  organizationSolarSystems,
  organizationSolarWaterHeaters,
  organizationUsers,
  organizationLinks,
  organizationHybridWaterHeaters,
  solarSystems,
  solarWaterHeaters,
)

// dockerイメージで起動した際に開発用ページが見られるように一時的に条件を外す
if (process.env.VUE_APP_ENV !== 'production') {
  routes.push({
    path:'/',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/TestLink.vue'),
    meta: { noGuard: true }
  })
} else {
  routes.push({
    path:'/',
    beforeEnter(){
      window.location = 'https://www.hyoukakyoukai.or.jp/'
    },
    meta: { noGuard: true }
  })
}

// 開発時にのみ参照するページが作成された場合、この上で条件付きroute追加の操作を実行すること
// Not Found
routes.push({
    path:'/*',
    component: () => import(/* webpackChunkName: "group-routes" */ '@/views/NotFound.vue'),
    meta: { noGuard: true }
})

export default routes