const routes = [
    {
        path: '/admin/electric_heat_pumps',
        component: () => import(/* webpackChunkName: "group-admin-electric-heat-pumps" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-admin-electric-heat-pumps" */ '@/views/ElectricHeatPumpList.vue'),
                meta: {
                    deviceCategoryKey: 'electricHeatPumps',
                    routeType: 'administrator'
                }
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "group-admin-electric-heat-pumps" */ '@/views/ElectricHeatPumpsDetail.vue'),
                meta: {
                    deviceCategoryKey: 'electricHeatPumps',
                    routeType: 'administrator',
                    type:'detail'
                }
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-electric-heat-pumps" */ '@/views/ElectricHeatPumpsDetail.vue'),
                meta: {
                    deviceCategoryKey: 'electricHeatPumps',
                    routeType: 'administrator',
                    type:'delete'
                }
            },
            {
                path: ':id/approved/enabled',
                component: () => import(/* webpackChunkName: "group-admin-electric-heat-pumps" */ '@/views/ElectricHeatPumpsDetail.vue'),
                meta: {
                    deviceCategoryKey: 'electricHeatPumps',
                    routeType: 'administrator',
                    type:'approvedEnabled'
                }
            },
        ]
    }
]

export default routes