<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  beforeCreate(){
    this.$gtag.event("NewVisit", {
      'event_category': "Visit"
    })
  },
}
</script>

<style lang="scss">
@charset "utf-8";

html{
  background-color: rgb(215, 227, 181);
}
html * {
  box-sizing:border-box;
}
body{
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0 20px ;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 500;
  color: #2c3e50;
  text-align: left;
  line-height: 1.5;
}

/* link */
a{
  &:link { 
    color: #111;
    text-decoration:none;
  }
  &:visited {
    color: #333;
  }
  &:hover { 
    color: #666;
    font-weight: bolder;
  }
  &:active {
    color: #555;
  }
}
/* 見出しと段落 */
.el_lv2Heading{
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}
.el_lv3Heading {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 20px;
}
.el_lv4Heading {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 18px;
}
.el_lv5Heading {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 800;
}
p.align_justify {
   text-align: justify;
}
img {
  display:inline-block;
  max-width: 100%;
}
/* 
---------------------------
入力部品系リセット
---------------------------
 */
/* ボタンなどの青枠を消す。フォントを反映 */
input[type="button"],
textarea,
select,
button {
  outline: none;
}
input,
textarea,
select,
button {
  font-weight: 500;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
.el_checkbox_input{
  display:none;
}
.el_checkbox_label{
  display: inline-flex;
  align-items: center;
  height: 16px;
  padding: 2px 2px 2px 20px;
  line-height: 16px;
  font-size: 14px;
  position: relative;
}
.el_checkbox_label:before{
  content:"";
  display: inline-flex;
  width: 1em;
  min-width: 1em;
  height: 1em;
  min-height: 1em;
  border: #ccc solid 1px;
  background-color: white;
  border-radius: 0.15em;
  position: absolute;
  top: 0.15em;
  left: 0;
}

.el_checkbox_input:checked+.el_checkbox_label:before,
.el_checkbox_label.checked:before{
  background-color: #3D9A46;
  border: #3D9A46 solid 1px;
}
.el_checkbox_input:checked+.el_checkbox_label:after,
.el_checkbox_label.checked:after{
  content:"";
  display: inline-flex;
  width: 0.55em;
  min-width: 0.55em;
  height: 0.3em;
  min-height: 0.3em;
  border-left: white solid 2px;
  border-bottom:white solid 2px;
  transform: rotate(-40deg);
  position: absolute;
  top: 0.3em;
  left: 0.2em;
}
/*
---------------------------
共通
---------------------------
 */
 /* ブロックのレイアウト */
.wrapper{
  width: 85%;
  min-width: 850px;
  max-width: 1280px;
  margin: 0 auto;
}
/* 可変幅のブロック */
.bl_contents_wrapper{
  width: fit-content;
  min-width: 600px;
  min-height: calc(100vh - 300px);
  margin-left: 0;
}

/* ローディングスピン表示中の半透明全画面を透明にする */
div.vld-overlay .vld-background{
  background-color: transparent;
  backdrop-filter: none !important;
}

/* ヘッダーのすぐ下のブロック：見出し、[事業者リンク][機器リストダウンロード]ボタン、メッセージ */
.bl_underHeader{
  display: flex;
  flex-wrap: wrap;
}
.bl_underHeader.bl_underHeader__wdfit{
  width: fit-content;
}
.ly_underHeader{
  padding: 16px 0 0;
}
.bl_underHeader .el_lv2Heading{
  width: auto;
  margin: 0;
  padding: 0 16px 0 0;
  line-height: 36px;
}
.bl_underHeader .el_lv2Heading+.el_button.inline{
  margin-left: auto;
}
.bl_underHeader .el_lv2Heading+.el_button.inline:hover{
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
}

.bl_underHeader .el_button.inline:last-of-type{
  min-width: 200px;
  margin-right: 0;
  white-space: nowrap;
}
.bl_underHeader p{
  margin: 0 0 8px;
}
.bl_underHeader button.el_button.inline{
  padding-right: 22px;
  padding-left: 22px;
  height: 36px;
  border-radius: 18px;
  background-color: #3D9A46;
}
/* ボタンのコンテナ */
.ly_buttonBox{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  padding: 1em 7em;
}
.ly_buttonBox .el_button:not(.cancel) {
  min-width: 10em;
}
.ly_buttonBox .el_button:not(.cancel):not(.delete):hover{
  background-color: #3D9A46;
}
.ly_buttonBox button.cancel{
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  left: -2px;
}
.ly_buttonBox .el_button:not(.cancel)~ button.delete{
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  right: -2px;
}
.ly_buttonBox .right{
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  right: 0;
}
/* セクション */
section:not(:first-of-type){
  margin-top: 48px;
  padding-top: 32px;
  border-top: 1px solid #F1F4EC;
}
/* indexページ等2カラムのページのレイアウト */
.bl_2columnWrapper{
  display: flex;
  margin-top: 20px;
  max-width: 100%;
}
.bl_2columnWrapper_main{
  width: 100%;
  min-width: calc(100% - 450px);
  margin-right: 30px;
}
.bl_2columnWrapper_sideBar{
  width: 450px;
  padding-left: 30px;
  border-left: 1px solid #707070;
}
/* フォームの外でのlabelとinput要素をまとめるコンテナ */
.bl_input_container{
  display: inline-flex;
  align-items: center;
}
.bl_input_container label.el_label{
  display: inline-block;
  margin-right: 16px;
}
/* 送信後等のメッセージボックス */
.bl_msgBox{
  width: fit-content;
  margin: 32px auto;
  padding: 32px;
  border: 1px solid #CDD6DD;
}
/* 検索窓 */
.bl_searchBox_outer{
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
}
.bl_searchBox_outer_msg{
  width: fit-content;
  margin: 0;
}
.bl_searchBox_outer_updateDate{
  width: 200px;
  margin: 0 0 0 auto;
  color: #004BB1;
  text-align:center;
  font-size: 13px;
}
.bl_searchBox{
  border: 1px solid #CDD6DD;
}
.ly_searchBox{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  padding: 20px;
}
.bl_searchBox p{
  display: block;
  width: 100%;
  margin: 0 0 8px;
}
.bl_searchBox .bl_input_container{
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1px;
}

.bl_searchBox .bl_input_container:last-of-type{
  margin-bottom: 0;
}
.bl_searchBox .input_container.formrow {
  margin-bottom: 2px;
  white-space: nowrap;
}
.bl_searchBox .input_container.formrow.hp_flexcolumn{
  display: inline-flex;
  flex-direction: column;
  margin-right: 16px;
}
.bl_searchBox .bl_input_container label.el_label,
.bl_searchBox .input_container label.el_label {
  font-size: 12px;
}
.bl_searchBox label{
  white-space: nowrap;
}
.bl_searchBox div.select_wrapper{
  margin-right: 18px;
}
.bl_searchBox button.el_button.inline{
  margin: 8px 0;
}

.bl_searchBox .hp_flexspacer{
  margin-bottom: 0;
}
/* テーブル */
table{
  margin: 16px 0;
  border-collapse: collapse;
  border: 2px solid #000;
}
th, td {
  padding: 4px 12px;
  border: 1px solid rgba(0, 0, 0, .5);
}
th{
  text-align: center;
  font-size: 12px;
  background-color: #F1F4EC;
  }
caption{
  margin-bottom: 4px;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #5C5C5C;
}
table tr td button.inline{
  white-space: nowrap;
  margin: 0;
}
/* 一覧ページのテーブル */
table.lists_table{
  width: fit-content;
  min-width: 800px;
  border: none;
}
table.lists_table thead th{
  position: sticky;
  top: 118px;
  z-index: 500;
  background-color: white;
  box-shadow: 0 1px 0 0 #4A6533;
}
table.lists_table th{
  padding: 2px 12px 2px 2px;
  background-color: transparent;
  border: none;
  border-bottom: 2px #4A6533 solid;
  text-align: left;
}
table.lists_table th>span{
  display: inline-block;
  white-space: nowrap;
}
table.lists_table th.lists_table_brand_name{
  min-width: 4.5em;
}
table.lists_table th.lists_table_pu_number{
  min-width: 6.5em;
}
table.lists_table th.lists_table_tu_number{
  min-width: 6.5em;
}
table.lists_table th.lists_table_bb_number{
  min-width: 6.5em;
}
table.lists_table th.lists_table_certificate_number{
  min-width: 5.5em;
}
table.lists_table th.lists_table_performanceValidationCategory{
  min-width: 4em;
}
table.lists_table th.lists_table_releaseYearMonth{
  min-width: 6em;
}
table.lists_table th.lists_table_states{
  min-width: 6em;
}
table.lists_table td{
  padding-left: 2px;
  border: none;
  border-bottom: 1px #C0D6DD solid;
}
table.lists_table tr>th:first-of-type,
table.lists_table tr>td:first-of-type{
  padding-left: 0;
}
table.lists_table tr>th:last-of-type,
table.lists_table tr>td:last-of-type{
  padding-right: 0;
}
table.lists_table a{
  color: #4A6533;
  border-bottom: 1px solid #4A6533;
}
table.lists_table a:hover{
  font-weight: 500;
}
table.lists_table .lists_table_relative:hover:after{
  color: transparent;
}
table.lists_table .lists_table_relative{
  position: relative;
}
table.lists_table .lists_table_relative:after{
  content:"…";
  display: inline-flex;
  width: 20px;
  margin: 0 0;
  padding: 0;
  margin-left: auto;
  font-size: 18px;
  line-height: 18px;
  color: #4A6533;
  z-index:0;
}
table.lists_table button.inline{
  margin: 1px;
  z-index:200;
}
table.lists_table td>.inline{
  color: #4A6533;
  background-color: white;
  border: 2px #C0D6DD solid;
}
table.lists_table td>.inline:hover{
  background-color: #4A6533;
  color: white;
  border: 2px #4A6533 solid;
}
table.lists_table td>.inline.delete:hover{
  background-color: #BE4316;
  color: white;
  border: 2px #BE4316 solid;
}
table.lists_table .lists_table_relative button:disabled{
  opacity: 1;
  background-color: #aaa;
  color: white;
}
table.lists_table .lists_table_hover{
  display: flex;
  align-items: center;
  position: absolute;
  height: fit-content;
  padding-top: 1px;
  padding-left: 32px;
  right: 0;
  top: 1px;
  white-space: nowrap;
  background: linear-gradient(to right, rgba(215, 227, 181, 0) , rgba(215, 227, 181, .95) 50px);
  z-index:190;
}
table.lists_table .lists_table_hover>button{
  margin-left: 8px;
}
.deviceLine:hover{
    background-color: rgba(215, 227, 181, 0.5);
}
/* 表の下にある表示切替…ボタン */
button.el_button.uq_togglebutton:not(.cancel){
    width: 80px;
    min-width: 80px;
    height: 36px;
    margin: 0.5rem 0 0.5rem;
    font-size: 18px;
    position: absolute;
    right: -2px;
    color: white !important; 
    background-color: #BE4316;
}
button.el_button.uq_togglebutton:not(.cancel):not(.delete):hover{
    background-color: #9e2a00;
}
.uq_foldedContainer{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  right: 0;
  top: 64px;
}
.uq_foldedContainer .el_button:not(.cancel) ~ button.delete{
  position: relative;
}
.uq_foldedContainer button.delete:hover{
  background-color: #9e2a00;
}

/* リンク一覧のテーブル */
.bl_linkLists_table_outer{
  width: fit-content;
  padding: 16px;
  border: 1px #C0D6DD solid;
}
.bl_linkLists_table_outer .linkLists_table{
  width: 100%;
  min-width: 300px;
  margin: 0;
}
table.linkLists_table{
  border: none;
}
table.linkLists_table th,
table.linkLists_table td{
  text-align: left;
  border: none; 
  border-bottom: 1px solid #C0D6DD;
}
table.linkLists_table tr td:first-child{
  width: 2em;
}
table.linkLists_table tr:last-child th,
table.linkLists_table tr:last-child td{
  border-bottom: none;
}
table.linkLists_table a{
  color: #4A6533;
  border-bottom: 1px solid #4A6533;
}

/* リスト */
ul{
  margin-top:8px;
  margin-bottom:8px;
}
li{
  margin-bottom: 1em;
}
/* 更新履歴とお知らせのリスト */
ul.bl_news{
  padding-inline-start: 0;
}
ul.bl_news>li{
  list-style-type: none;
}
.bl_news_list{
  list-style-type: none;
  border-bottom: 1px solid #CDD6DD;
  margin-bottom: 22px;
}
.bl_news_list:last-child{
  border-bottom: none;
}
.bl_news_list time{
  margin-right: 16px;
  font-size: 14px;
  font-weight: bolder;
}
.bl_news_list_title{
  display:inline-block;
  margin: 0 auto 16px 0;
  font-size: 14px;
  font-weight: bolder;
}
.bl_news_list time + .bl_news_list_title{
  margin: 0 auto 0 0;
}
.bl_news_list time + .bl_news_list_text{
  margin: 0;
}
.bl_news_list_text{
  display:inline-block;
  margin: 0 0 16px;
}
/* フォームのレイアウト */
.ly_form_box{
    width: fit-content;
    padding: 16px 22px;
    border: 1px solid #CDD6DD;
}
.ly_form_box div.common_text_column,
.ly_form_box div.formrow {
  display:grid;
  display:-ms-grid;
  grid-template-columns: 200px 1fr;
  -ms-grid-columns: 200px 1fr;
  grid-template-rows: auto;
  -ms-grid-rows: auto;
  align-items: flex-start;
  width: auto;
  padding: 4px 0;
  border-bottom: 1px solid #F1F4EC;
}

.ly_form_box div.common_text_column:last-child,
.ly_form_box div.formrow:last-child {
  border-bottom: none;
}
.ly_form_box div.formrow>.rowcontents{
  -ms-grid-column: 2;
  -ms-grid-row: 1;
}
.ly_form_box div.formrow p{
  margin: 0;
}

input[type="password"]{
  box-sizing: border-box;
  width: 12em;
  height: 26px;
  margin: 0;
  padding: 2px 8px;
  line-height: 20px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
textarea{
  min-height: 3em;
  margin: 0;
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: vertical;
}

/* どこでも使えるスタイル（ヘルパー） */
/* 全角スペース */
.hp_zenkakuspace{
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: 0;
  padding: 0;
}
/* 改行禁止 */
.hp_nowrap{
  white-space: nowrap;
}
/* インライン要素をセンター */
.hp_alignCenter{
  text-align: center;
}
/* インライン要素を左揃え */
.hp_alignLeft{
  text-align: left;
}
/* flex要素の中で透明な水平線を入れて改行 */
.hp_flexspacer{
  width: 100%;
  height: 0;
  margin: 0;
  border-top: 0;
  border-color: transparent;
}
/* 共通の必須ラベル */
  .lbl_required:after{
      content: "必須";
      display: inline-block;
      box-sizing: border-box;
      height: 16px;
      margin-top: 0;
      margin-left: auto;
      margin-right: 8px;
      padding: 2px 6px;
      color: white;
      background-color: #F75977;
      font-size: 10px;
      line-height: 12px;
      white-space: nowrap;
      border-radius: 2px;
  }
  /* 文字を太く */
  .hp_str_bold{
    font-weight: bolder;
  }
  .hp_str_underline{
    border-bottom: 1px solid currentColor;
  }
</style>
