import Vue from 'vue'
import { isSet } from '@/util'

/**
 * エラー管理モジュール
 * @module store/modules/error
 */

const state = {
    errors:[],
}

const getters = {
    // stateを一括で返す
    errors(state){
        return state
    },
}

const actions = {
    // stateの一括更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // stateの一括削除を呼び出す
    clear({commit}){
        commit('clear')
    },
}

const mutations = {
    // stateの一括更新
    update(state, response) {
        if (response.status == 422 && isSet(response.data.errors)) {
            Object.keys(response.data.errors).forEach(key => {
                if(response.data.errors[key].length !== 0 && typeof response.data.errors[key][0] === 'string') {
                    state.errors.push(response.data.errors[key][0])
                } else if (typeof response.data.errors[key] === 'object') {
                    Object.keys(response.data.errors[key]).forEach(childKey => {
                        state.errors.push(response.data.errors[key][childKey][0])
                    })
                }
            })
        } else {
            state.errors.push(response.data.message)
        }
    },
    // stateの一括削除
    clear(state) {
        Vue.set(state, 'errors', [])
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}