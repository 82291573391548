<template>
    <div class="bl_header_outer ly_header_outer">
        <header>
            <h1 @click="moveTo('/top')">
                <div class="header_flex_box">
                    <div class="header_rogo"></div>
                    <span class="header_shadan">一般社団法人</span>
                    <span class="header_kyokai">住宅性能評価・表示協会</span>
                </div>
            </h1>
        </header>
        <div class="bl_header_outer_title ly_header_outer_title">
            <h2>
                <span class="ly_header_outer_title_title">温熱・省エネ設備機器ポータルサイト</span>
                <span
                    v-if="title == '温熱・省エネ設備機器ポータルサイト(住宅版)'"
                    class="bl_header_outer_title_root"
                >
                    住宅版
                </span>
                <span
                    v-else-if="title == '温熱・省エネ設備機器ポータルサイト(非住宅版)'"
                    class="bl_header_outer_title_root"
                >
                    非住宅版
                </span>
                <span
                    v-else-if="title == '温熱・省エネ設備機器ポータルサイト(管理者)'"
                    class="bl_header_outer_title_root"
                >
                    管理者
                </span>
            </h2>
            <div class="bl_header_outer_title_command ly_header_outer_title_command">
                <app-button
                    v-if="isVisiblePublic"
                    name="公開ページ"
                    class="inline"
                    @click="publicPage"
                    ref="jest-header-public"
                />
                <app-button
                    v-if="isVisibleLogout"
                    name="ログアウト"
                    class="inline logout"
                    @click="logout"
                    ref="jest-header-logout"
                />
                <app-button
                    v-if="isVisiblePassUpdate"
                    name="パスワード変更"
                    class="inline"
                    @click="passwordUpdate"
                    ref="jest-header-password-update"
                />
                <app-button
                    v-if="isVisibleJigyosyaLogin"
                    name="情報提供事業者ログイン"
                    class="inline hover_color__active"
                    @click="login"
                    ref="jest-header-login"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { move } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

/**
 * 共通ヘッダー
 *
 * [プロパティ]
 * title ... タイトル
 */
export default {
    name: 'LayoutHeader',
    props: {
        /**
         * タイトル
         * @type {String}
         */
        title: {
            type: String,
            required: true
        },
        isVisiblePublic: {
            type: Boolean,
            required: true
        },
        isVisibleLogout: {
            type: Boolean,
            required: true
        },
        isVisiblePassUpdate: {
            type: Boolean,
            required: true
        },
        isVisibleJigyosyaLogin: {
            type: Boolean,
            required: true
        },
        isHouse: {
            type: Boolean,
            required: true
        },
    },
    computed:{
        ...mapGetters({
            authUser:'auth/user',
        }),
        type(){
            return this.isHouse ? 'house' : 'building'
        },
    },
    methods:{
        ...mapActions({
            authLogout:'auth/logout',
            authUpdate:'auth/update',
            updateStatus:'status/update',
            userUpdate:'register/user/update'
        }),
        async publicPage(){
            await this.updateStatus({type:this.type})
            const path = this.isHouse ? '/house/links' : '/building/links'
            this.moveTo(path)
        },
        moveTo(path){
            move(this.$router, path)
        },
        login(){
              const path = this.isHouse ? '/login/house' : '/login/building'
            this.moveTo(path)
        },
        logout: async function(){
            await Promise.all([
                this.authLogout(),
                this.updateStatus({type:this.type})
            ])
                .then(() =>{
                    this.moveTo('/logout')
                    // ユーザをクリアするタイミングが遷移後かつモジュール外でないと、
                    // OrganizationDetailでログインユーザの情報が読み込めずにコンソールエラーとなる
                    this.authUpdate({
                        user:[],
                        message:'',
                        errors:{},
                        organization:{},
                    })
                })
        },
        passwordUpdate(){
            const updateUser = cloneDeep(this.authUser)
            this.$set(updateUser, 'password', null)
            this.$set(updateUser, 'newPassword', null)
            this.$set(updateUser, 'newPasswordConfirmation', null)
            this.userUpdate(updateUser)
            this.moveTo('/password')
        }
    }
}
</script>

<style scoped>
.bl_header_outer{
    background-color: rgb(215, 227, 181);
}
.ly_header_outer{
    width: 100%;
    height: 100px;
    padding: 0 20px ;
    z-index: 1000;
    top: 0;
    position: fixed;
    left: 0;
}
header{
    display:block;
    width: calc(85% + 4px);
    min-width: 850px;
    max-width: 1280px;
    height: 44px;
    margin: 20px auto 0;
    background-color: #FFF;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    border-radius: 22px;
}
h1{
    display: flex;
    align-items: center;
    height: 44px;
    margin:0;
}
h1 .header_flex_box{
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
}
h1 .header_rogo{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 74px;
    height: 74px;
    margin-left: -4px;
    background: url(/img/rogo_kyokai.png) 0 0 no-repeat;
    background-size: auto 42px;
    background-position-x: 20px;
    background-position-y: center;
    background-color: white;
    border-radius: 2.8125rem;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
}
h1 .header_shadan{
    content: "";
    display: inline-block;
    width: 89px;
    height: 16px;
    margin: 0 6px 0 10px;
    color: transparent;
    background: url(/img/rogo_shadan.png) 0 0 no-repeat;
    background-size: auto 18px;
    overflow: hidden;
}
h1 .header_kyokai{
    content: "";
    display: inline-block;
    width: 240px;
    height: 26px;
    color: transparent;
    background: url(/img/rogo_kyokai_1.png) 0 0 no-repeat;
    background-size: auto 27px;
    overflow: hidden;
}
.bl_header_outer header>p{
    display: block;
    margin: -1em 0 0 6.5rem;
}
.ly_header_outer_title{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    height: 33px;
    min-width: 850px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 5px 0 2px;
}
.bl_header_outer_title h2{
    display: flex;
    align-items: center;
    margin: 0 0 0 79px;
    font-size: 22px;
    line-height: 22px;
}
.bl_header_outer_title h2 .ly_header_outer_title_title{
    font-size: clamp(14px, 1.2vw, 20px);
}
.ly_header_outer_title_command{
    display: inline-flex;
    flex-wrap: nowrap;
}
.ly_header_outer_title_command>.inline{
    white-space: nowrap;
}

.ly_header_outer_title_command button{
    margin: 0 2px;
}
.ly_header_outer_title_command button:last-child{
    margin-right: -1px;
}
.bl_header_outer_title_root{
    display: inline-flex;
    align-items: center;
    height: 20px;
    margin: 0 8px;
    padding: 2px 12px;
    font-size: 12px;
    line-height: 16px;
    border: 2px currentColor solid;
    border-radius: 4px;
    white-space: nowrap;
}
.logout{
    color: white;
    background-color: #777;
}
</style>
