import Vue from 'vue'
import axios from '@/api'
import { isSet } from '@/util'

/**
 * お知らせ管理モジュール
 * @module store/modules/informations
 */

// state
const state = {
    // お知らせの内容
    informations:[],
    information:{},
    total:null,
    page:1,
}

const getters = {
    informations(state){
        return state.informations
    },
    information(state){
        return state.information
    },
    total(state){
        return state.total
    },
    page(state) {
        return state.page
    }
}

const actions = {
    // informationを取得してお知らせ一覧を更新
    getInformations({state, commit}, {show_type}){
        let params = {
            page:state.page
        }
        if (isSet(show_type)) {
            Vue.set(params, 'show_type', show_type)
        }
        return axios.get('/api/information', {params})
            .then(response => {
                commit('update', {
                    informations:response.data.information,
                    total:response.data.total
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // informationの更新時などに一括で値を更新する
    update({commit}, values) {
        commit('update', values)
    },
    // informationの更新時などに一括で値を更新する
    updateInformation({commit}, values) {
        commit('updateInformation', values)
    },
    create({state, commit}){
        commit('updateInformation', {show_users:0})
        return axios.post('/api/information', state.information)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    edit({state, commit}){
        commit('updateInformation', {show_users:0})
        return axios.put('/api/information/' + state.information.id, state.information)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    delete({state}){
        return axios.delete('/api/information/' + state.information.id)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    }
}

const mutations = {
    // stateを更新する
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    updateInformation(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state.information, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}