const routes = [
    {
        path: '/organization/users',
        component: () => import(/* webpackChunkName: "group-organization-users" */ '@/App.vue'),
        children: [
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "group-organization-users" */ '@/views/OrganizationUsersCreate.vue'),
            },
            {
                path: 'create/confirm',
                component: () => import(/* webpackChunkName: "group-organization-users" */ '@/views/OrganizationUsersCreateConfirm.vue'),
            },
            {
                path: 'create/complete',
                component: () => import(/* webpackChunkName: "group-organization-users" */ '@/views/OrganizationUsersCreateComplete.vue'),
            },
            {
                path: ':id/edit',
                component: () => import(/* webpackChunkName: "group-organization-users" */ '@/views/OrganizationUsersEdit.vue'),
            },
            {
                path: ':id/edit/confirm',
                component: () => import(/* webpackChunkName: "group-organization-users" */ '@/views/OrganizationUsersEditConfirm.vue'),
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-organization-users" */ '@/views/OrganizationUsersDelete.vue'),
            },
        ]
    }
]

export default routes