import Vue from 'vue'

/**
 * ステータス管理モジュール
 * @module store/modules/user
 */

// state
const state = {
    // house or building
    type:null,
    // 利用規約に同意したか否か
    accepted:false
}

const getters = {
    // 住宅かどうかを返す
    isHouse(state){
        return state.type == 'house' || state.type == null
    },
    // 利用規約に同意したか否かを返す
    accepted(state){
        return state.accepted
    }
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    }
}

const mutations = {
    // stateを更新する
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}