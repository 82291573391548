const routes = [
    {
        path: '/admin/evaluation_organizations',
        component: () => import(/* webpackChunkName: "group-admin-evaluation-organizations" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-admin-evaluation-organizations" */ '@/views/AdminEvaluationOrganizationList.vue'),
            },
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "group-admin-evaluation-organizations" */ '@/views/AdminEvaluationOrganizationEdit.vue'),
                meta: {type:'create'}
            },
            {
                path: ':id/edit',
                component: () => import(/* webpackChunkName: "group-admin-evaluation-organizations" */ '@/views/AdminEvaluationOrganizationEdit.vue'),
                meta: {type:'edit'}
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-evaluation-organizations" */ '@/views/AdminEvaluationOrganizationDelete.vue'),
            },
        ]
    }
]

export default routes