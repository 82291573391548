import Vue from 'vue'
import axios from '@/api'

/**
 * 共有パスワード管理モジュール
 * @module store/modules/register/sharedPasswords
 */

// state
const state = {
    sharedPasswords:[],
    total:0,
    page:1,
    sharedPassword:{},
}

const getters = {
    // 共有パスワード一覧を返す
    sharedPasswords(state){
        return state.sharedPasswords
    },
    // ページネータ用のデータを返す
    pageStatus(state){
        return {
            total:state.total,
            page:state.page
        }
    },
    // 共有パスワードの編集情報を返す
    sharedPassword(state){
        return state.sharedPassword
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // sharedPasswordの更新を呼び出す
    updateSharedPassword({commit}, values){
        commit('updateSharedPassword', values)
    },
    // 共有パスワード一覧を取得
    getSharedPasswords({state, commit}){
        return axios.get('/api/shared_passwords', {params:{
            page:state.page
        }})
            .then(response => {
                commit('update', {
                    sharedPasswords:response.data.shared_passwords,
                    total:response.data.total
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // 共有パスワードの作成
    create({state}){
        return axios.post('/api/shared_passwords', {
            name:state.sharedPassword.name,
            zip_password:state.sharedPassword.zip_password,
            zip_password_confirmation:state.sharedPassword.zip_password_confirmation,
            note:state.sharedPassword.note
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // 共有パスワードの更新
    edit({state}){
        return axios.put('/api/shared_passwords/' + state.sharedPassword.id, {
            name:state.sharedPassword.name,
            zip_password:state.sharedPassword.zip_password,
            zip_password_confirmation:state.sharedPassword.zip_password_confirmation,
            note:state.sharedPassword.note
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // 共有パスワードの削除
    delete({state}){
        return axios.delete('/api/shared_passwords/' + state.sharedPassword.id)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // sharedPasswordの更新
    updateSharedPassword(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state.sharedPassword, key, values[key])
        })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}