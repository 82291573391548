const routes = [
    {
        path: '/admin/organizations',
        component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/App.vue'),
        children: [
            {
                path:'',
                component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/views/AdminOrganizationList.vue'),
            },
            {
                path:':id',
                component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/views/AdminOrganizationDetail.vue'),
                meta:{type:'detail'}
            },
            {
                path:':id/approved/enabled',
                component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/views/AdminOrganizationApprove.vue'),
                meta:{type:'enabled'}
            },
            {
                path:':id/approved/disabled',
                component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/views/AdminOrganizationApprove.vue'),
                meta:{type:'disabled'}
            },
            {
                path:':id/edit',
                component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/views/AdminOrganizationEdit.vue'),
                meta:{type:'edit'}
            },
            {
                path:':id/edit/confirm',
                component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/views/AdminOrganizationEditConfirm.vue'),
                meta:{type:'confirm'}
            },
            {
                path:':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-organizations" */ '@/views/AdminOrganizationDelete.vue'),
                meta:{type:'confirm'}
            },
        ]
    }
]

export default routes