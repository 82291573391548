<template>
    <div class="bl_userHeader ly_userHeader">
        <app-button
            name="<span>事業者</span><span>情報管理</span>"
            @click="moveTo('/organization/organization')"
            ref="jest-user-header-organization"
            :class="{bl_userHeader_link__active: isActive === '/organization/organization'}"
            class="bl_userHeader_link uq_flexgrow3"
        />
        <app-button
            name="<span>リンク管理</span>"
            @click="moveTo('/organization/links')"
            ref="jest-user-header-links"
            :class="{bl_userHeader_link__active: isActive === '/organization/links'}"
            class="bl_userHeader_link uq_flexgrow3"
        />
        <app-button
            v-if="authOrganization.cogeneration_manager"
            name="<span>コージェネレーション</span><span>機器管理</span>"
            @click="moveTo('/organization/cogeneration_systems')"
            ref="jest-user-header-cogeneration-systems"
            :class="{bl_userHeader_link__active: isActive === '/organization/cogeneration_systems'}"
            class="bl_userHeader_link"
        />
        <app-button
            v-if="authOrganization.hybrid_water_heater_manager"
            name="<span>ハイブリッド給湯</span><span>機器管理</span>"
            @click="moveTo('/organization/hybrid_water_heaters')"
            ref="jest-user-header-hybrid-water-heaters"
            :class="{bl_userHeader_link__active: isActive === '/organization/hybrid_water_heaters'}"
            class="bl_userHeader_link"
        />
        <app-button
            v-if="authOrganization.electric_heat_pump_manager"
            name="<span>電気ヒートポンプ</span><span>給湯機器管理</span>"
            @click="moveTo('/organization/electric_heat_pumps')"
            ref="jest-user-header-electric-heat-pumps"
            :class="{bl_userHeader_link__active: isActive === '/organization/electric_heat_pumps'}"
            class="bl_userHeader_link"
        />
        <app-button
            v-if="authOrganization.solar_system_manager"
            name="<span>ソーラーシステム</span><span>機器管理</span>"
            @click="moveTo('/organization/solar_systems')"
            ref="jest-user-header-solar-systems"
            :class="{bl_userHeader_link__active: isActive === '/organization/solar_systems'}"
            class="bl_userHeader_link"
        />
        <app-button
            v-if="authOrganization.solar_water_heater_manager"
            name="<span>太陽熱</span><span>温水器機器管理</span>"
            @click="moveTo('/organization/solar_water_heaters')"
            ref="jest-user-header-solar-water-heaters"
            :class="{bl_userHeader_link__active: isActive === '/organization/solar_water_heaters'}"
            class="bl_userHeader_link"
        />
    </div>
</template>

<script>
import { move } from '@/util'
import { mapActions, mapGetters } from 'vuex'

/**
 * 共通ヘッダー
 *
 * [プロパティ]
 * title ... タイトル
 */
export default {
  name: 'UserHeader',
  computed:{
      ...mapGetters({
          authOrganization:'auth/organization'
      }),
      isActive(){
          /* 現在のpathに応じて、メニューボタンをアクティブにする */
          let path = this.$route.path
          if(path.indexOf('/organization/links') >= 0){
              return '/organization/links'
          }
          else if(path.indexOf('/organization/cogeneration_systems') >= 0){
              return '/organization/cogeneration_systems'
          }
          else if(path.indexOf('/organization/hybrid_water_heaters') >= 0){
              return '/organization/hybrid_water_heaters'
          }
          else if(path.indexOf('/organization/electric_heat_pumps') >= 0){
              return '/organization/electric_heat_pumps'
          }
          else if(path.indexOf('/organization/solar_systems') >= 0){
              return '/organization/solar_systems'
          }
          else if(path.indexOf('/organization/solar_water_heaters') >= 0){
              return '/organization/solar_water_heaters'
          }
          else(path.indexOf('/organization') >= 0);{
              return '/organization/organization'
          }
      },
  },
  methods:{
        ...mapActions({
            clearError:'error/clear',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
    }
}
</script>

<style scoped>
.bl_userHeader{
    background-color: #d7e3b5;
    border-bottom:#4A6533 4px solid;
}
.ly_userHeader{
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    width: calc(85% - 34px);
    min-width: 850px;
    max-width: 1280px;
    height: 40px;
    z-index: 1000;
    top: 100px;
    position: fixed;
}
.bl_userHeader_link{
    flex-grow: 2;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 7em;
    height: 40px;
    margin: 0 2px;
    padding: 4px 16px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 8px 8px 0 0 ;
    background-color: white;
    border-bottom:#4A6533 4px solid;
    color:#4A6533;
    transition: background-color .3s ease;
}
.bl_userHeader_link:first-of-type{
    margin-left: 0;
}
.bl_userHeader_link:last-of-type{
    margin-right: 0;
}
.bl_userHeader_link:hover{
    transform: none;
    background-color: #3D9A46;
    color: white;
}
.bl_userHeader_link__active{
    background-color: #4A6533;
    color: white;
}
.bl_userHeader_link__active:hover{
    background-color: #4A6533;
}
.bl_userHeader_link >>> span{
    display: inline-block;
    white-space: nowrap;
    margin: 0;
    line-height: 1;
}
.uq_flexgrow3{
    flex-grow: 3;
}
</style>
