const routes = [
    {
        path: '/admin/solar_systems',
        component: () => import(/* webpackChunkName: "group-admin-solar-systems" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-admin-solar-systems" */ '@/views/SolarSystemList.vue'),
                meta: {
                    deviceCategoryKey: 'solarSystems',
                    routeType: 'administrator'
                }
            },
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "group-admin-solar-systems" */ '@/views/SolarSystemsDetail.vue'),
                meta: {
                    deviceCategoryKey: 'solarSystems',
                    routeType: 'administrator',
                    type:'detail'
                }
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-solar-systems" */ '@/views/SolarSystemsDetail.vue'),
                meta: {
                    deviceCategoryKey: 'solarSystems',
                    routeType: 'administrator',
                    type:'delete'
                }
            },
            {
                path: ':id/approved/enabled',
                component: () => import(/* webpackChunkName: "group-admin-solar-systems" */ '@/views/SolarSystemsDetail.vue'),
                meta: {
                    deviceCategoryKey: 'solarSystems',
                    routeType: 'administrator',
                    type:'approvedEnabled'
                }
            },
        ]
    }
]

export default routes