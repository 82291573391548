import Vue from 'vue'
import axios from '@/api'

/**
 * 事業者編集モジュール
 * @module store/modules/register/organization
 */

// state
const state = {
    // 事業者一覧
    organizations:[],
    // 事業者一覧のページ数
    page:1,
    // 事業者一覧の件数
    total:0,
    //事業者一覧検索時の事業者タイプ
    searchType:null,
    //事業者一覧検索時の事業者名キーワード
    keyword:'',
    //事業者一覧検索時のステータス
    searchStatus:null,
    //事業者一覧検索時の日付指定の種類
    dateType:'',
    //事業者一覧検索時の開始日
    startDate:null,
    //事業者一覧検索時の終了日
    finishDate:null,

    // 機器の管理権限がある事業者名のリスト
    brandOrganizations:[],

    // 事業者詳細を表示する対象の事業者
    organization:{},
    // 事業者詳細で表示するユーザの一覧
    users:[],
    // 事業者編集画面で選択されたロック解除対象ユーザーのIDの配列
    idList:[]
}

const getters = {
    // 事業者一覧を返す
    organizations(state){
        return state.organizations
    },
    // 検索用のパラメータを返す
    getSearchParameters(state){
        return {
            page:state.page,
            total:state.total,
            searchType:state.searchType,
            keyword:state.keyword,
            searchStatus:state.searchStatus,
            dateType:state.dateType,
            startDate:state.startDate,
            finishDate:state.finishDate,
        }
    },
    // 機器の管理権限がある事業者名のリストを返す
    brandOrganizations(state){
        return state.brandOrganizations
    },
    // 事業者詳細を表示する対象の事業者を取得
    organization(state){
        return state.organization
    },
    // 事業者詳細で表示するユーザの一覧を取得
    users(state){
        return state.users
    },
    idList(state){
        return {
            idList:state.idList
        }
    }
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // 事業者一覧を取得
    getOrganizations({state, commit}){
        return axios.get('/api/organizations', {
            params:{
                page:state.page,
                type:state.searchType,
                keyword:state.keyword.trim(),
                status:state.searchStatus,
                date_type:state.dateType,
                start_date:state.startDate,
                finish_date:state.finishDate,
            }
        })
            .then(response => {
                commit('update', {
                    organizations:response.data.organizations,
                    total:response.data.total
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // 機器の管理権限がある事業者一覧を取得
    getBrandOrganizations({commit}){
        return axios.get('/api/organizations', {
            params:{
                device_manager:true,
            }
        })
            .then(response => {
                commit('update', {
                    brandOrganizations:response.data.organizations,
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // 事業者詳細で表示する事業者情報を取得
    getOrganization({state, commit}) {
        return axios.get('/api/organizations/' + state.organization.id)
            .then(response => {
                commit('update', {organization:response.data.organizations[0]})
                return response
            }, reject => {
                return reject.response
            })
    },
    // 事業者詳細で表示するユーザの一覧を取得
    getOrganizationUsers({state, commit}) {
        return axios.get('/api/organizations/' + state.organization.id + '/users')
            .then(response => {
                commit('update', {users:response.data.users})
                return response
            }, reject => {
                return reject.response
            })
    },
    // 事業者の承認
    enabled({state}) {
        return axios.put('/api/organizations/' + state.organization.id + '/approved/enabled', {
            cogeneration_manager:state.organization.cogeneration_manager,
            hybrid_water_heater_manager:state.organization.hybrid_water_heater_manager,
            electric_heat_pump_manager:state.organization.electric_heat_pump_manager,
            solar_system_manager:state.organization.solar_system_manager,
            solar_water_heater_manager:state.organization.solar_water_heater_manager,
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // 事業者の承認取消
    disabled({state}) {
        return axios.put('/api/organizations/' + state.organization.id + '/approved/disabled')
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // 事業者の更新
    edit({state}) {
        let params = state.organization
        params.zip_password_confirmation = params.zip_password
        return axios.put('/api/organizations/' + state.organization.id, params)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // 事業者の削除
    delete({state}) {
        return axios.delete('/api/organizations/' + state.organization.id)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // state.organizationsの更新
    updateOrganization({commit}, values){
        commit('updateOrganization', values)
    },
    // 事業者ユーザーのロック解除
    unlockUsers({state}){
        if(state.idList.length !== 0) {
            return axios.put('/api/users/unlock', {id_list:state.idList})
                .then(response => {
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            // Statusを遷移の可否判定に使うので、idListが空なら通信無しで200を返しておく
            return {status:200}
        }
    }
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // state.organizationの更新
    updateOrganization(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state.organization, key, values[key])
        })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}