import Vue from 'vue'
import axios from '@/api'
import { getCSVFile, isSet } from '@/util'
import { cloneDeep } from 'lodash'

/**
 * 電気ヒートポンプ給湯機モジュール
 * @module store/modules/electricHeatPumps
 */

const state = {
    electric_heat_pumps:[],
    last_update:'',
    total:0,
    searchElectricHeatPumpsData:{
        brand_name:'',
        release_date_from:'',
        publish_date_from:'',
        include_expired:false,
        pu_number:'',
        tu_number:'',
        system_number:'',
        certificate_number:'',
        status:'',
        page:1,
        start_date:null,
        finish_date:null,
        date_type:'',
    },
    brand_names:[],
    electric_heat_pump_detail:{},
    electric_heat_pump_certificates:{},
    canceled_at:null,
    reason:[],
    note:'',
    publish_date:null,
    release_date:null,
}

const getters = {
    // electric_heat_pumpsを一括で返す
    electricHeatPumps(state){
        return state.electric_heat_pumps
    },
    // last_updateを一括で返す
    lastUpdate(state){
        return state.last_update
    },
    // totalを一括で返す
    total(state){
        return state.total
    },
    // 電気ヒートポンプ給湯機の検索で使用する値を一括で返す
    searchElectricHeatPumpsData(state){
        return state.searchElectricHeatPumpsData
    },
    // 機器ブランド名の一覧を返す
    brandNames(state){
        return state.brand_names
    },
    // electric_heat_pump_detailを返す
    electricHeatPumpsDetail(state){
        return state.electric_heat_pump_detail
    },
    // 電気ヒートポンプ給湯機証明書を返す
    electricHeatPumpsSystemCertificates(state){
        return state.electric_heat_pump_certificates
    },
    // 取下げ時の入力項目を返す
    cancelReason(state) {
        return {
            canceled_at:state.canceled_at,
            reason:state.reason,
            note:state.note,
        }
    },
    // 情報公開日等更新に必要な日付情報を返す
    publishDates(state){
        return {
            publish_date:state.publish_date,
            release_date:state.release_date
        }
    }
}

const actions = {
    /**
     * 検索条件が初期化された状態で電気ヒートポンプ給湯機の一覧を取得する
     * @param {Boolean} publicFlag 公開ページからの呼び出しかどうかを判定するフラグ(ログイン中か否かにかかわらず公開情報を呼び出すため)
     */
    getElectricHeatPumps({state, commit}, {publicFlag, includeExpired}){
        commit('update', {
            searchElectricHeatPumpsData:{
                brand_name:'',
                release_date_from:'',
                include_expired:includeExpired,
                pu_number:'',
                tu_number:'',
                system_number:'',
                certificate_number:'',
                page:1
            }
        })
        let params = cloneDeep(state.searchElectricHeatPumpsData)
        params.public = publicFlag
        return axios.get('/api/electric_heat_pumps', {params})
            .then(response => {
                commit('update', response.data)
            })
    },
    /**
     * 電気ヒートポンプ給湯機の詳細を取得する
     * @param {Number, String} id 電気ヒートポンプ給湯機のID
     */
    getDeviceDetail({commit}, {id, isPublic}){
        return axios.get('/api/electric_heat_pumps/' + id, {params:{public:isPublic}})
            .then(response => {
                commit('update', {electric_heat_pump_detail:response.data.electric_heat_pumps[0]})
            }, () => {
                // 取得に失敗した場合はデータをクリア
                commit('update', {electric_heat_pump_detail: {}})
            })
    },
    /**
     * PDFを除く電気ヒートポンプ給湯機の証明書情報を返す
     * @param {Number, String} id 電気ヒートポンプ給湯機のID
     */
    getElectricHeatPumpsCertificate({commit}, id){
        return axios.get('/api/electric_heat_pump_certificates/' + id)
            .then(response => {
                commit('update', {electric_heat_pump_certificates:response.data.electric_heat_pump_certificates[0]})
                return response
            }, reject => {
                // 取得に失敗した場合はデータをクリア
                commit('update', {electric_heat_pump_certificates: {}})
                return reject.response
            })
    },
    /**
     * 電気ヒートポンプ給湯機の証明書PDFを返す
     * @param {Number, String} id 電気ヒートポンプ給湯機のID
     */
    // eslint-disable-next-line no-unused-vars
    getDeviceCertificateFile({commit}, id){
        return axios.get('/api/electric_heat_pump_certificates/' + id + '/file')
            .then(response => {
                return response.data.file
            })
    },
    /**
     * 電気ヒートポンプ給湯機の検索を実行する
     * @param {Object} param1 page:取得するページ数、publicFlag:公開ページからの取得か否かの判定
     */
    search({state, commit}, {page, publicFlag}){
        commit('updateSearchElectricHeatPumpsData', {page})
        if (publicFlag) {
            commit('updateSearchElectricHeatPumpsData', {page, status:''})
        }
        
        let params = cloneDeep(state.searchElectricHeatPumpsData)
        params.pu_number = params.pu_number.trim()
        params.tu_number = params.tu_number.trim()
        params.system_number = params.system_number.trim()
        params.certificate_number = params.certificate_number.trim()
        params.public = publicFlag

        return axios.get('/api/electric_heat_pumps', {params})
            .then((response) => {
                commit('update', response.data)
            })
    },
    /**
     * 電気ヒートポンプ給湯機のブランド名一覧を取得する
     */
    getBrandNames({commit}, isPublic = true){
        return axios.get('/api/electric_heat_pumps/brand_names', {params:{include_expired:true, public:isPublic}})
            .then(response => {
                // 配列になっていたりオブジェクトになっていたりでレスポンスが一定しないので形を整える
                const brand_names = Object.keys(response.data.brand_names).map(key => {
                    return response.data.brand_names[key]
                })
                commit('update', {brand_names})
            })
    },
    /**
     * 電気ヒートポンプ給湯機の一覧CSVをダウンロードする
     */
    download(){
        return axios.get('/api/electric_heat_pumps/download/csv')
            .then(response => {
                getCSVFile(response)
                return response.status
            })
            .catch(reject => {
                //エラーメッセージを表示
                alert('CSVの出力に失敗しました')
                return reject.response.status
            })
    },
    /**
     * 事業者電気ヒートポンプ給湯機の一覧CSVを検索しダウンロードする
     */
    organizationDownload({state}){
        let params = cloneDeep(state.searchElectricHeatPumpsData)
        params.pu_number = params.pu_number.trim()
        params.tu_number = params.tu_number.trim()
        params.system_number = params.system_number.trim()
        params.certificate_number = params.certificate_number.trim()
        params.public = false
        params.page = null

        return axios.get('/api/electric_heat_pumps/download/organization_csv', {params})
            .then(response => {
                getCSVFile(response)
                return response.status
            })
            .catch(reject => {
                //エラーメッセージを表示
                alert('CSVの出力に失敗しました')
                return reject.response.status
            })
    },
    /**
     * 電気ヒートポンプ給湯機の建研用CSVをダウンロードする
     */
    kenkenDownload(){
        return axios.get('/api/electric_heat_pumps/download/kenken_csv')
            .then(response => {
                getCSVFile(response)
                return response.status
            })
            .catch(reject => {
                //エラーメッセージを表示
                alert('CSVの出力に失敗しました')
                return reject.response.status
            })
    },
    /**
     * 電気ヒートポンプ給湯機証明書の建研用CSVをダウンロードする
     */
    kenkenCertificateDownload(){
        return axios.get('/api/electric_heat_pump_certificates/download/kenken_csv')
            .then(response => {
                getCSVFile(response)
                return response.status
            })
            .catch(reject => {
                //エラーメッセージを表示
                alert('CSVの出力に失敗しました')
                return reject.response.status
            })
    },
   /**
     * 電気ヒートポンプ給湯器を削除する
     */
    deleteDevice({state}) {
        return axios.delete('/api/electric_heat_pumps/' + state.electric_heat_pump_detail.id)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    /**
     * 電気ヒートポンプ給湯器の公開申請を実行する
     */
    publishDevice({state}) {
        return axios.put('/api/electric_heat_pumps/' + state.electric_heat_pump_detail.id + '/apply_publish')
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    /**
     * 電気ヒートポンプ給湯器の取下申請を実行する
     */
    publishCancelDevice({state}) {
        const params = {
            canceled_at:state.canceled_at,
            reason:state.reason,
            note:state.note,
        }
        return axios.put('/api/electric_heat_pumps/' + state.electric_heat_pump_detail.id + '/apply_cancel', params)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    /**
     * 電気ヒートポンプ給湯器の取下を実行する
     */
    cancelDevice({state}) {
        const params = {
            canceled_at:state.canceled_at,
            reason:state.reason,
            note:state.note,
        }
        return axios.put('/api/electric_heat_pumps/' + state.electric_heat_pump_detail.id + '/cancel', params)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    /**
     * 情報公開日と発売予定日の更新
     */
    updatePublishDate({state}, isEditablePublishDate) {
        if (isSet(state.release_date)) {
            const params = {
                publish_date:isEditablePublishDate ? state.publish_date : null,
                release_date:state.release_date,
            }
            return axios.put('/api/electric_heat_pumps/' + state.electric_heat_pump_detail.id + '/date', params)
                .then(response => {
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            const errors = {}
            if (!isSet(state.release_date)) {
                errors.release_date = ['発売予定年月日は必須です']
            }
            return {
                status:422,
                data:{
                    errors
                },
            }
        }
    }, 
    /**
     * 電気ヒートポンプ給湯器の承認を実行する
     */
    approveEnableDevice({state}) {
        return axios.put('/api/electric_heat_pumps/' + state.electric_heat_pump_detail.id + '/approve/enabled')
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    /**
     * 電気ヒートポンプ給湯機の検索条件を更新する
     * @param {Object} values 更新対象をキー、更新内容を値に持つオブジェクト
     */
    updateSearchElectricHeatPumpsData({commit}, values){
        commit('updateSearchElectricHeatPumpsData', values)
    },
    /**
     * Storeの各値の更新
     * @param {Object} values 更新対象のキーと値の組
     */
    update({commit}, values){
        commit('update', values)
    },
}

const mutations = {
    // stateを更新する
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // SearchElectricHeatPumpsDataを更新する
    updateSearchElectricHeatPumpsData(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state.searchElectricHeatPumpsData, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}