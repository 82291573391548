import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import routes from './routes.js'
Vue.use(Router)

const router = new Router({
    routes
})

/**
 * ToDo:Vue Router の ルートメタフィールド を使って認証が必要か判断
 * @see https://router.vuejs.org/ja/guide/advanced/meta.html
 */
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.noGuard) === false
    && store.getters['auth/user'] == undefined){
        //認証必要かつログイン状態が確認できない
        next('/')
    } else {
        //認証不要なのでそのまま通過 */
        next()
    }
})

export default router

/* ページの制作進行する方は、ご担当されたページのルートをroutes.jsにも記入してください */