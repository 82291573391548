import Vue from 'vue'
import axios from '@/api'

/**
 * ファイルアップロードモジュール
 * @module store/modules/file
 */

// initial state
const state = {
    // フォームに登録されたファイル名
    fileName: null,
    // フォームに登録されたファイル
    file: {},
    // アップロードの処理中フラグ
    uploadDisabled: false,
    // passwordType
    passwordType: 'organization',
    // sharedPasswordId
    sharedPasswordId: null,
    // 共有パスワードを持つ機関名の一覧
    organizationNameList: [],
    // CSVの内容
    csv: [],
    // CSVアップロード時に返されるduplicatesの内容
    duplicates: [],
    // CSVアップロード時に返されるerrorsの内容
    errors: [],
    // CSVアップロード時に返されるexistsの内容
    exists: [],
    // 送信するCSVデータのNo
    noList: [],
}

// getters
const getters = {
    // State全件取得
    getAll(state) {
        return state
    },
    // CSVの検証結果を返す
    getCsv(state) {
        return state.csv
    },
    // CSVの検証により取得された、問題点があるデータのnoの配列の一覧を返す
    getWarning(state) {
        return {
            duplicates: state.duplicates,
            errors: state.errors,
            exists: state.exists,
        }
    },
    // 登録する機器データのNo.の配列
    noList(state) {
        return {
            noList: state.noList
        }
    }
}

const actions = {
    // stateの更新を呼び出す
    update({ commit }, values) {
        commit('update', values)
    },
    // 第三者機関の名称リストを取得
    updateOrganizationNameList({ commit }) {
        return axios.get('/api/shared_passwords')
            .then(response => {
                commit('update', { organizationNameList: response.data.shared_passwords })
                return response
            })
    },
    // Cogenerationのバリデーション実行
    checkCogenerations({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            return axios.post('/api/cogeneration_systems/check', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // Cogenerationの新規登録
    uploadCogenerations({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            // Fileを送るため、FormData()を使用する
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }

            // formDataで配列を送るため、配列の中身の数だけno_list[]にappendする必要がある
            state.noList.forEach(number => {
                formData.append('no_list[]', number)
            })

            return axios.post('/api/cogeneration_systems', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // HybridWaterHeatersのバリデーション実行
    checkHybridWaterHeaters({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            return axios.post('/api/hybrid_water_heaters/check', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // HybridWaterHeatersの新規登録
    uploadHybridWaterHeaters({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            // Fileを送るため、FormData()を使用する
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            // formDataで配列を送るため、配列の中身の数だけno_list[]にappendする必要がある
            state.noList.forEach(number => {
                formData.append('no_list[]', number)
            })

            return axios.post('/api/hybrid_water_heaters', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // ElectricHeatPumpsのバリデーション実行
    checkElectricHeatPumps({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            return axios.post('/api/electric_heat_pumps/check', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // ElectricHeatPumpsの新規登録
    uploadElectricHeatPumps({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            // Fileを送るため、FormData()を使用する
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            // formDataで配列を送るため、配列の中身の数だけno_list[]にappendする必要がある
            state.noList.forEach(number => {
                formData.append('no_list[]', number)
            })

            return axios.post('/api/electric_heat_pumps', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // SolarSystemsのバリデーション実行
    checkSolarSystems({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            return axios.post('/api/solar_systems/check', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // SolarSystemsの新規登録
    uploadSolarSystems({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            // Fileを送るため、FormData()を使用する
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            // formDataで配列を送るため、配列の中身の数だけno_list[]にappendする必要がある
            state.noList.forEach(number => {
                formData.append('no_list[]', number)
            })

            return axios.post('/api/solar_systems', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // SolarWaterHeaterのバリデーション実行
    checkSolarWaterHeaters({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            return axios.post('/api/solar_water_heaters/check', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
    // SolarWaterHeatersの新規登録
    uploadSolarWaterHeaters({ state, commit }) {
        if (state.fileName != null && state.fileName.endsWith('.zip')) {
            // Fileを送るため、FormData()を使用する
            let formData = new FormData()
            formData.append('zip', state.file)
            formData.append('password_type', state.passwordType)
            if (state.passwordType === 'shared' && state.sharedPasswordId !== null) {
                formData.append('shared_password_id', state.sharedPasswordId)
            }
            // formDataで配列を送るため、配列の中身の数だけno_list[]にappendする必要がある
            state.noList.forEach(number => {
                formData.append('no_list[]', number)
            })

            return axios.post('/api/solar_water_heaters', formData, { 'Content-Type': 'multipart/form-data' })
                .then(response => {
                    commit('update', {
                        csv: response.data.csv,
                        duplicates: response.data.duplicates,
                        errors: response.data.errors,
                        exists: response.data.exists,
                    })
                    return response
                }, reject => {
                    return reject.response
                })
        } else {
            return {
                status: 422,
                data: {
                    errors: { file: ['ZIPファイルを選択してください'] }
                }
            }
        }
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
