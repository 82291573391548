<template>
    <div class="listHeader bl_administratorHeader ly_administratorHeader">
        <app-button
            name="事業者管理"
            @click="moveTo('/admin/organizations')"
            ref="jest-admin-header-organizations"
            :class="{bl_administratorHeader_link__active: isActive('/admin/organizations')}"
            class="bl_administratorHeader_link uq_flexgrow3"
        />
        <app-button
            name="リンク管理"
            @click="moveTo('/admin/links')"
            ref="jest-admin-header-links"
            :class="{bl_administratorHeader_link__active: isActive('/admin/links')}"
            class="bl_administratorHeader_link uq_flexgrow3"
        />
        <div class="bl_administratorHeader_link__foldButton uq_flexgrow3">
            <app-button
                name="機器管理"
                @click="toggleDevices"
                ref="jest-admin-header-devices"
                :class="{bl_administratorHeader_link__active: isActiveDevice,
                bl_administratorHeader_link__open: showDevices }"
                class="bl_administratorHeader_link"
            />
            <div
                v-show="showDevices"
                class="device_list bl_deviceList"
                ref="jest-admin-header-device-list"
            >
                <app-button
                    name="コージェネレーション"
                    @click="moveTo('/admin/cogeneration_systems')"
                    ref="jest-admin-header-cogeneration-systems"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/cogeneration_systems')}"
                    class="el_foldedlist"
                />
                <app-button
                    name="ハイブリッド給湯機"
                    @click="moveTo('/admin/hybrid_water_heaters')"
                    ref="jest-admin-header-hybrid-water-heaters"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/hybrid_water_heaters')}"
                    class="el_foldedlist"
                />
                <app-button
                    name="電気ヒートポンプ給湯機"
                    @click="moveTo('/admin/electric_heat_pumps')"
                    ref="jest-admin-header-electric-heat-pump"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/electric_heat_pumps')}"
                    class="el_foldedlist"
                />
                <app-button
                    name="ソーラーシステム"
                    @click="moveTo('/admin/solar_systems')"
                    ref="jest-admin-header-solar-systems"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/solar_systems')}"
                    class="el_foldedlist"
                />
                <app-button
                    name="太陽熱温水器"
                    @click="moveTo('/admin/solar_water_heaters')"
                    ref="jest-admin-header-solar-water-heaters"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/solar_water_heaters')}"
                    class="el_foldedlist"
                />
            </div> 
        </div>
        <app-button
            name="お知らせ管理"
            @click="moveTo('/admin/information')"
            ref="jest-admin-header-information"
            :class="{bl_administratorHeader_link__active: isActive('/admin/information')}"
            class="bl_administratorHeader_link uq_flexgrow3"
        />
        
        <div class="bl_administratorHeader_link__foldButton bl_administratorHeader_link__foldButton__5 uq_flexgrow3">
            <app-button
                name="マスタ管理"
                @click="toggleMasters"
                ref="jest-admin-header-master"
                :class="{bl_administratorHeader_link__active: isActiveMaster,
                bl_administratorHeader_link__open: showMasters
                }"
                class="bl_administratorHeader_link bl_administratorHeader_link__master"
            />
            <div
                v-show="showMasters"
                class="master_list bl_deviceList"
                ref="jest-admin-header-master-list"
            >
                <app-button
                    name="ブランド事業者"
                    @click="moveTo('/admin/brand_names')"
                    ref="jest-admin-header-brand-names"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/brand_names')}"
                    class="el_foldedlist"
                />
                <app-button
                    name="共有パスワード"
                    @click="moveTo('/admin/shared_passwords')"
                    ref="jest-admin-header-shared-passwords"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/shared_passwords')}"
                    class="el_foldedlist"
                />
                <app-button
                    name="評価機関"
                    @click="moveTo('/admin/evaluation_organizations')"
                    ref="jest-admin-header-evaluation-organizations"
                    :class="{bl_administratorHeader_link__active: isActive('/admin/evaluation_organizations')}"
                    class="el_foldedlist"
                />
            </div>
        </div>
        <div 
            v-if="showDevicesOrMasters" 
            @click="foldLists" class="uq_selecting"
        />
    </div>
</template>

<script>
import { move } from '@/util'
import { mapActions } from 'vuex'

/**
 * 管理者用ヘッダー
 */
export default {
  name: 'AdminHeader',
  data(){
      return {
          showDevices:false,
          showMasters:false,
      }
  },
  computed:{
      isActive(){
          /* 現在のpathに応じて、メニューボタンをアクティブにする */
          return function(path) {
              return this.$route.path.indexOf(path) >= 0
          }
      },
      isActiveDevice(){
          return this.isActive('/admin/cogeneration_systems') || this.isActive('/admin/hybrid_water_heaters')
            || this.isActive('/admin/electric_heat_pumps') || this.isActive('/admin/solar_systems')
            || this.isActive('/admin/solar_water_heaters') || this.showDevices
      },
      isActiveMaster(){
          return this.isActive('/admin/brand_names') || this.isActive('/admin/shared_passwords') || this.isActive('/admin/evaluation_organizations') || this.showMasters
      },
      showDevicesOrMasters(){
          return this.showDevices || this.showMasters
      }
  },
  methods:{
        ...mapActions({
            clearError:'error/clear',
        }),
        toggleDevices(){
            this.showDevices = !this.showDevices
            this.showMasters = false
        },
        toggleMasters(){
            this.showMasters = !this.showMasters
            this.showDevices = false
        },
        foldLists(){
            this.showMasters = false
            this.showDevices = false
        },
        moveTo(path){
            this.clearError()
            this.showDevices = false
            this.showMasters = false
            move(this.$router, path)
        },
    }
}
</script>

<style scoped>
.bl_administratorHeader{
    background-color: #d7e3b5;
    border-bottom:#4A6533 4px solid;
}
.ly_administratorHeader{
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    width: calc(85% - 34px);
    min-width: 850px;
    max-width: 1280px;
    height: 40px;
    z-index: 1000;
    top: 100px;
    position: fixed;
    overflow: visible;
}
.bl_administratorHeader_link{
    flex-grow: 2;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: calc(100% - 4px);
    min-width: 7em;
    height: 40px;
    margin: 0;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 8px 8px 0 0 ;
    border-bottom:#4A6533 4px solid;
    background-color: white;
    color:#4A6533;
    transition: background-color .3s ease;
}
.bl_administratorHeader>.bl_administratorHeader_link:first-of-type{
    margin-left: 0;
}
.bl_administratorHeader_link__foldButton{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    z-index: 9990;
}
.bl_administratorHeader_link__foldButton__5{
    margin-right: 0 !important;
}
.bl_administratorHeader_link__foldButton>.bl_administratorHeader_link{
    width: 100%;
    max-width: 100%;
    min-width: 10.5em;
    height: 40px;
    min-height: 40px;
    margin: 0;
    border-bottom: 4px solid #4A6533;
}

.bl_administratorHeader_link__foldButton>.bl_administratorHeader_link:before{
    content: "";
    display: inline-flex;
    margin-right: auto;
}
.bl_administratorHeader_link__foldButton>.bl_administratorHeader_link:after{
    content: "";
    display: inline-flex;
    width: 16px;
    height: 8px;
    margin-left: auto;
    background: url(/img/icon_set.png);
    background-repeat: no-repeat;
    background-size: auto 14px;
    background-position-x: 25%;
}
.bl_administratorHeader_link__open:after{
    transform: rotate(180deg);
}
.bl_administratorHeader_link__foldButton>.bl_administratorHeader_link__master{
    min-width: 7em;
}
.bl_administratorHeader_link:hover,
.bl_administratorHeader_link__foldButton>.bl_administratorHeader_link__active{
    background-color: #3D9A46;
    color: white;
}
.bl_administratorHeader_link__active{
    background-color: #4A6533;
    color: white;
}
.bl_administratorHeader_link__active:hover{
    background-color: #4A6533;
}
.bl_administratorHeader_link >>> span{
    display: inline-block;
    white-space: nowrap;
    margin: 0;
    line-height: 1;
}
.bl_deviceList{
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 1px 2px rgba(0,0,0, .1);
}
.bl_deviceList>.el_foldedlist{
    width: 100%;
    margin: 0;
    padding: 8px 0;
    background-color: white;
    color: #4A6533;
    border-bottom: 1px solid #4A6533;}

.bl_deviceList>.el_foldedlist:hover{
    color:white;
    background-color: #3D9A46;
    transform: scale(1);
}
.uq_flexgrow3{
    flex-grow: 3;
    margin: 0 2px;
}
.uq_flexgrow3.bl_administratorHeader_link__foldButton:last-child{
    margin-right: 0;
}
.el_foldedlist{
    border-radius: 0;
    z-index: 9990;
}
.uq_selecting{
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0);
    z-index: 900;
}
</style>
