<template>
    <label
        :for="name"
        class="el_label"
    >
        {{label}}
    </label>
</template>

<script>
export default {
    name: 'AppLabel',
    props:{
        /**
         * labelタグのfor属性に指定する値
         * @type {String}
         */
        name: {
            type: String,
            default: null
        },
        /**
         * labelタグのinnerText
         */
        label: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    label,
    .el_label {
        font-size:14px;
        line-height: 16px;
        margin:0.25rem 0;
        font-weight:600;
        color:#1C4373;
        display:flex;
        align-items:center;
    }
</style>