import Vue from 'vue'
import axios from '@/api'
import { isSet } from '@/util'

/**
 * ユーザ編集モジュール
 * @module store/modules/register/user
 */

// state
const state = {
    // 部署
    division:'',
    // 担当者名
    name:'',
    // 担当者電話番号
    tel:'',
    // メールアドレス
    email:'',
    // メールアドレス（確認）
    email_confirmation:'',
    // パスワード
    password:'',
    // パスワード（確認）
    password_confirmation:'',
    // パスワード
    newPassword:'',
    // パスワード（確認）
    newPasswordConfirmation:'',
}

const getters = {
    // stateを一括で返す
    getAll(state){
        return state
    },
    //メールアドレスとパスワードがそれぞれ確認用のものと一致しているかどうかを返す
    prevalidate(state) {
        return isSet(state.email) && state.email === state.email_confirmation && isSet(state.password) && state.password === state.password_confirmation
    },
    //メールアドレスとパスワードがそれぞれ確認用のものと一致していない時にエラー文を返す
    prevalidateError(state) {
        let errors = {
            email:[],
            password:[],
        }
        if(isSet(state.email) === false){
            errors.email.push('メールアドレスを入力してください')
        } else if(state.email !== state.email_confirmation) {
            errors.email.push('メールアドレスが確認用メールアドレスと一致しません')
        }
        if(isSet(state.password) === false){
            errors.password.push('パスワードを入力してください')
        } else if(state.password !== state.password_confirmation) {
            errors.password.push('パスワードが確認用パスワードと一致しません')
        }
        return errors
    },
    //メールアドレスが確認用のものと一致しているかどうかを返す
    prevalidateEmail(state) {
        return isSet(state.email) && state.email === state.email_confirmation
    },
    //メールアドレスが確認用のものと一致していない時にエラー文を返す
    prevalidateEmailError(state) {
        let errors = {
            email:[],
        }
        if(isSet(state.email) === false){
            errors.email.push('メールアドレスを入力してください')
        } else if(state.email !== state.email_confirmation) {
            errors.email.push('メールアドレスが確認用メールアドレスと一致しません')
        }
        return errors
    },
    //パスワードが確認用のものと一致しているかどうかを返す
    prevalidatePassword(state) {
        return isSet(state.newPassword) && state.newPassword === state.newPasswordConfirmation
    },
    //パスワードが確認用のものと一致していない時にエラー文を返す
    prevalidatePasswordError(state) {
        let errors = {
            password:[],
        }
        if(isSet(state.newPassword) === false){
            errors.password.push('パスワードを入力してください')
        } else if(state.newPassword !== state.newPasswordConfirmation) {
            errors.password.push('パスワードが確認用パスワードと一致しません')
        }
        return errors
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // ユーザデータの作成
    createUser({state}){
        return axios.post('/api/users', state)
    },
    // ユーザデータの更新
    updateUser({state}){
        return axios.put('/api/users/' + state.id, state)
    },
    // ユーザデータの削除
    deleteUser({state}){
        return axios.delete('/api/users/' + state.id)
    },
    // パスワードの更新
    updatePassword({state}){
        return axios.put('/api/users/' + state.id + '/password', {
            old_password:state.password,
            new_password:state.newPassword,
            new_password_confirmation:state.newPasswordConfirmation
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}