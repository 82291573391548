import Vue from 'vue'
import axios from '@/api'

/**
 * 評価機関管理モジュール
 * @module store/modules/register/evaluationOrganizations
 */

// state
const state = {
    evaluationOrganizations:[],
    evaluationOrganization:{},
}

const getters = {
    // 評価機関一覧を返す
    evaluationOrganizations(state){
        return state.evaluationOrganizations
    },
    // 評価機関の編集情報を返す
    evaluationOrganization(state){
        return state.evaluationOrganization
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // evaluationOrganizationの更新を呼び出す
    updateEvaluationOrganization({commit}, values){
        commit('updateEvaluationOrganization', values)
    },
    // 評価機関一覧を取得
    getEvaluationOrganizations({commit}){
        return axios.get('/api/evaluation_organizations')
            .then(response => {
                commit('update', {
                    evaluationOrganizations:response.data.evaluation_organizations,
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // 評価機関の作成
    create({state}){
        return axios.post('/api/evaluation_organizations', {
            name:state.evaluationOrganization.name,
            number:state.evaluationOrganization.number,
            password:state.evaluationOrganization.password,
            password_confirmation:state.evaluationOrganization.password_confirmation,
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // 評価機関の更新
    edit({state}){
        return axios.put('/api/evaluation_organizations/' + state.evaluationOrganization.id, {
            name:state.evaluationOrganization.name,
            number:state.evaluationOrganization.number,
            password:state.evaluationOrganization.password,
            password_confirmation:state.evaluationOrganization.password_confirmation,
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // 評価機関の削除
    delete({state}){
        return axios.delete('/api/evaluation_organizations/' + state.evaluationOrganization.id)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // evaluationOrganizationの更新
    updateEvaluationOrganization(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state.evaluationOrganization, key, values[key])
        })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}