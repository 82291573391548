<template>
    <div class="radio" @click="click">
        <label
            :class="{checked:isChecked}"
            :disabled="disabled"
            @click="click"
        >
            {{label}}
        </label>
        <input
            type="radio"
            :name="name"
            :value="value"
            :disabled="disabled"
            @change="onChange($event.target.value)"
        />
    </div>
</template>

<script>
export default {
    data(){
        return {
           classObject:{

           }
        }
    },
    mounted: function(){
        // 実用上は不要と思われるが、マウント時に、input要素のcheckedを書き換えないと
        // テスト実行に具合が悪い
        this.$el.querySelectorAll('input')[0].checked = this.isChecked
    },
    computed:{
        isChecked(){
            if (this.value == null) return false
            if (this.checkedValue == null) return false
            return this.value.toString() == this.checkedValue.toString()
        }
    },
    model: {
        prop: 'checkedValue',   //v-modelでは`value`プロパティのバインドされが、ここでは`checkedValue`にバインドさせる。
        event: 'change'
    },
    props: {
        value: {
            type: [String, Boolean, Number],
            required: true
        },
        label: {
            type: String,
            required: true
        },
        checkedValue: {
            type: [String, Boolean, Number],
            default: ''
        },
        name: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        //ラベルがクリックされたとき
        click(event){
            //checked属性を書き換え
            this.$el.querySelectorAll('input')[0].checked = true
            //値変更の通知
            this.$emit('change', this.value)
            //イベント発火
            this.$emit('click', event)
        },
    }
}
</script>

<style scoped>
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
}
input[type="radio"]{
    display: none;
    cursor: pointer;
}
.radio label {
    position: relative;
    padding-left: 1.5em;
    margin-right: 1em;
}
.radio label::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #ddd;
    border-radius: 50%;
    background: #eee;
}
.radio label.checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 11px;
    height: 11px;
    background: #4A6533;
    border-radius: 50%;
}

.radio{
    display:inline-block;
    margin:0.25rem 0.125rem;
}
label{
    box-sizing: border-box;
    font-size: 14px;
    line-height: 16px;
    color: #707070;
    white-space: nowrap;
    cursor: pointer;
}
label.checked{
    font-weight: bolder;
}

label[disabled="disabled"]{
    color: #707070;
    font-weight:500;
}
.bold{
    font-weight: bold;
}
</style>