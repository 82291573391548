<template>
    <div class="radio">
        <label
            :class="{checked:(isChecked)}"
            :disabled="disabled"
            @click="click"
        >
            {{label}}
        </label>
        <input
            type="checkbox"
            :name="name"
            :value="value"
            :checked="isChecked"
            :disabled="disabled"
        />
    </div>
</template>

<script>
export default {
    name:'AppCheckBox',
    props: {
        value: {
            type: [String, Boolean],
            required: true
        },
        label: {
            type: String,
            required: true
        },
        checkedValue: {
            type: [Array],
            required: true
        },
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    mounted: function(){
        // 実用上は不要と思われるが、マウント時に、input要素のcheckedを書き換えないと
        // テスト実行に具合が悪い
        this.$el.querySelectorAll('input')[0].checked = this.isChecked
    },
    computed:{
        isChecked(){
            return this.checkedValue.includes(this.value.toString())
        }
    },
    model: {
        prop: 'checkedValue',   //双方向バインドされる変数
        event: 'change'         //値変更時に発火させるイベント
    },
    methods:{
        //ラベルがクリックされたとき
        click(event){
            //実態要素
            let el = this.$el.querySelectorAll('input')[0]
            //checked属性を書き換え
            el.checked = !el.checked
            //値変更の通知
            this.$emit('change', this.value)
            //イベント発火
            this.$emit('click', event)
        }
    }
}
</script>

<style scoped>
.radio{
    display:inline-block;
    margin:0.125rem;
}
input[type="checkbox"]{
    display: none;
}
label{
    box-sizing: border-box;
    padding: 4px 16px;
    font-size: 14px;
    color: #707070;
    border-radius: 4px;
    border: 1px dashed #707070;
}
label.checked{
    padding: 2px 14px;
    font-weight: bolder;
    color: #4A6533;
    border: 3px solid #4A6533;
}
label[disabled="disabled"]{
    color: #707070;
    font-weight:500;
    border: 1px dashed #707070;
}
.bold{
    font-weight: bold;
}
</style>