import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import error from '@/store/modules/error.js'
import register from '@/store/modules/register'
import admin from '@/store/modules/admin'
import status from '@/store/modules/status.js'
import links from '@/store/modules/links.js'
import informations from '@/store/modules/informations.js'
import cogenerations from '@/store/modules/cogenerations.js'
import hybridWaterHeaters from '@/store/modules/hybridWaterHeaters.js'
import progress from '@/store/modules/progress'
import auth from '@/store/modules/auth.js'
import file from '@/store/modules/file.js'
import electricHeatPumps from '@/store/modules/electricHeatPumps.js'
import solarSystems from '@/store/modules/solarSystems.js'
import solarWaterHeaters from '@/store/modules/solarWaterHeaters.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    error,        // エラー管理モジュール
    admin,       // 管理モジュール
    register,       // 登録モジュール
    status,         // ステータスモジュール
    links,         // リンクモジュール
    informations,         // お知らせモジュール
    cogenerations,  // コージェネレーション機器モジュール
    hybridWaterHeaters, //ハイブリッド給湯機器モジュール
    progress,   //プログレス表示モジュール
    auth,         // ログインモジュール
    file,         // ファイルアップロードモジュール
    electricHeatPumps, // 電気ヒートポンプ給湯機モジュール
    solarSystems, // ソーラーシステムモジュール
    solarWaterHeaters, // ソーラーシステムモジュール
  },
  plugins: [createPersistedState({storage: window.sessionStorage})],
  strict: process.env.VUE_APP_ENV !== 'production'
})
