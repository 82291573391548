<template>
    <div
        :class="{disabled : disabled_bool}"
        class="select_wrapper"
        @click="toggleLists"
    >
        <div class="select_btn">
            <span
                v-if="isAnyVmodelValue"
                v-html="vmodelLabel"
            />
            <span v-if="!isAnyVmodelValue">{{label}}</span>
        </div>
        <div v-if="!hidden" class="uq_selecting"></div>
        <ul
            :class="{hidden : hidden}"
            class="select"
            @change="hide"
        >
            <slot></slot>
        </ul>
    </div>
</template>

<!--メモと呼び出し側の例
    <app-select
        v-model="series"
        :label="'商品シリーズ'"
    >
        <app-select-option
            value="a"
            v-model="vModelName"
        >
            aaaa
        </app-select-option>
    </app-select>
-->
<script>
/**
 * 選択ボックス
 *
 * [イベント]
 * click ... 表示非表示状態が変わった時 引数=表示状態(表示時true)
 */
import { isSet } from '@/util'

export default {
    name: 'AppSelect',
    data(){
        return {
          hidden: Boolean,
          v_model_value:this.vmodelValue,
          disabled_bool:this.disabled
        }
    },
    model: {
        prop: 'vmodelValue',
        event: 'change'
    },
    watch:{
        vmodelValue(){
            this.v_model_value = this.vmodelValue
        },
        disabled(){
            this.disabled_bool = this.disabled
        }
    },
    props: {
        vmodelValue: {
            type: [String, Boolean, Number],
            required: false
        },
        vmodelLabel: {
            type: [String, Boolean, Number],
            required: false
        },
        label:{
            type: String,
            required: true
        },
        disabled:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        isAnyVmodelValue(){
            return isSet(this.vmodelValue)
        }
    },
    methods:{
        toggleLists(){
            if(this.disabled == false){
                this.hidden = !this.hidden
            }
            this.$emit('click', !this.hidden)
        },
        hide(){
            if (!this.hidden) {
                this.hidden = true
            }
        }
    }
}
</script>

<style scoped>
.select_wrapper{
    display: inline-block;
    margin-right: 2px;
    position: relative;
}
.select_wrapper .uq_selecting{
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0);
    z-index: 9998;
}
.select_wrapper div.select_btn{
    display: inline-block;
    max-width: auto;
    min-height: 28px;
    margin: 2px 0;
    box-sizing: border-box;
    white-space: normal;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    line-height: 20px;
    padding: 4px 32px 4px 7px;
    color: black;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%234A6533%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .6em 1em, 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ccc;
    border-image: initial;
    white-space: nowrap;
    cursor: pointer;
}
.select_wrapper div.select_btn span{
    font-size: 12px;
    font-weight: 500;
}
ul{
    width: fit-content;
    max-height: 30vh;
    background-color: #ddd;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    z-index: 9999;
}
ul.hidden{
    height: 0;
}
ul:not(.hidden){
    height: auto;
    margin: 0;
    position: absolute;
    z-index: 9999;
}

_:-ms-lang(x)::-ms-backdrop, ul:not(.hidden){
    padding-left: 0;
}
ul.hidden li{
    display: none;
    height: 0;
}
ul:not(.hidden) li{
    height: auto;
    white-space: nowrap;
}

ul.hidden label{
    display: none;
    height: 0;
}

ul:not(.hidden) label{
    height: auto;
}

ul li:first-child{
    border-top-right-radius: .5em;
    border-top-left-radius: .5em;
}
ul li:last-child{
    border-bottom-right-radius: .5em;
    border-bottom-left-radius: .5em;
}
.disabled .select_btn{
    color: #888;
    background-color: #444;
}
.disabled{
    opacity: 0.5;
}

_:-ms-lang(x)::-ms-backdrop, .select_wrapper{
    height: 28px;
}
</style>