<template>
    <div :class="{common_text_column : label, input_container:true}">
        <app-label
            v-if="label"
            :name="name"
            :label="label"
            :class="{lbl_required: lbl_required}"
            class="rowlabel grid1"
        />
        <app-text-box
            class="rowcontents grid2"
            :text-box-id="textBoxId"
            :value="value"
            :name="name"
            :type="type"
            :unit="unit"
            :step="step"
            @input="input"
            @change="change"
            :disabled="disabled"
            :placeholder="placeholder"
            @keydown_enter="keydown_enter"
            :min="min"
        />
    </div>
</template>

<script>
import AppTextBox from '@/components/atoms/AppTextBox.vue'
import AppLabel from '@/components/atoms/AppLabel.vue'

export default {
    name: 'AppText',
    components:{
        AppTextBox,
        AppLabel
    },
    props:{
        /**
         * input要素のname属性に指定する値. label要素のfor属性にも指定される
         * @type {String}
         */
        name: {
            type: [String, undefined],
            default: undefined
        },
        /**
         * ラベル (label要素のinnerText)
         * @type {String}
         */
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            required: false
        },
        type:{
            type: String,
            required: false,
            default: 'text'
        },
        unit: {
            type: String,
        },
        step: {
            type: [String, Number]
        },
        disabled: {
            type: [String, Boolean]
        },
        placeholder: {
            type: String
        },
        min: {
            type: [Number, String]
        },
        textBoxId: {
            type: String
        },
        lbl_required: {
            type: Boolean
        },
        information: {
            type:String
        }
    },
    methods:{
        change(value){
            this.$emit('change', value)
        },
        input(value){
            this.$emit('input', value)
        },
        keydown_enter(){
            this.$emit('keydown_enter')
        }
    }
}
</script>

<style scoped>
    label{
        -ms-grid-column: 1;
        -ms-grid-row: 1;
    }
    input{
        -ms-grid-column: 2;
        -ms-grid-row: 1;
    }
    /* 指定したクラスによってテキストボックスの長さを調整する */
    .address >>> input[type="text"]{
        width: 34em;
    }
    .mail_address >>> input[type="text"],
    .company_name >>> input[type="text"]{
        width: 25em;
    }
    .wd_100per >>>input[type="text"]{
        width: 100%;
    }
</style>
