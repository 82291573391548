<template>
  <div
    :class="{'isScroll':scrolling, 'isAdministrator':isAdministrator, 'isOrganization':isOrganization }"
    class="wrapper default_template">
    <layout-header
      :title="title"
      :is-visible-public="isVisiblePublic"
      :is-visible-logout="isVisibleLogout"
      :is-visible-pass-update="isVisiblePassUpdate"
      :is-visible-jigyosya-login="isVisibleJigyosyaLogin"
      :is-house="isHouse"
    />
    <administrator-header
      v-if="isVisibleAdministratorHeader"
    />
    <user-header
      v-if="isVisibleUserHeader"
    />
    <breadcrumb
       v-if="isVisibleBreadcrumb && !isOrganization && !isAdministrator"
      :is-house="isHouse"
      :is-visible-breadcrumb="isVisibleBreadcrumb"
      :breadcrumb-category="breadcrumbCategory"
      :breadcrumb-item="breadcrumbItem" />
    <div class="bl_container ly_container">
        <slot></slot>
    </div>
    <app-footer />
  </div>
</template>

<script>
/**
 * 共通テンプレート
 *
 * [プロパティ]
 * title ... タイトル
 */
import LayoutHeader from '@/components/molecules/LayoutHeader.vue'
import AdministratorHeader from '@/components/molecules/AdministratorHeader.vue'
import AppFooter from '@/components/molecules/AppFooter.vue'
import UserHeader from '@/components/molecules/UserHeader.vue'
import Breadcrumb from '@/components/molecules/Breadcrumb.vue'

export default {
  name: 'Default',
  components:{
    AppFooter,
    LayoutHeader,
    AdministratorHeader,
    UserHeader,
    Breadcrumb
  },
  props: {
    /**
     * タイトル
     * @type {String}
     */
    title: {
      type:String,
      required: true
    },
    isVisiblePublic: {
        type: Boolean,
        required: true
    },
    isVisibleLogout: {
        type: Boolean,
        required: true
    },
    isVisiblePassUpdate: {
        type: Boolean,
        required: true
    },
    isVisibleJigyosyaLogin: {
        type: Boolean,
        required: true
    },
    isHouse: {
        type: Boolean,
        required: true
    },
    /* パンくずリスト */
    isVisibleBreadcrumb:{
        type: Boolean,
        required: false,
        default: false,
    },
    breadcrumbCategory:{
        type:String,
        required: false
    },
    breadcrumbItem:{
        type:String,
        required: false
    },
    /* ユーザーヘッダー */
    isVisibleUserHeader:{
        type: Boolean,
        required: false,
        default: false,
    },
    /* 管理者ヘッダー */
    isVisibleAdministratorHeader:{
        type: Boolean,
        required: false,
        default: false,
    }
  },
  computed:{
    /**
     * 事業者ページか否か
     */
    isOrganization() {
        return this.$route.meta.routeType === 'organization'
    },
    /**
     * 管理者ページか否か
     */
    isAdministrator() {
        return this.$route.meta.routeType === 'administrator'
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  data(){
    return{
      scrolling: false

    }
  },
  methods:{
    handleScroll(){
      if(!this.isVisibleAdministratorHeader && !this.isVisibleUserHeader){
        //スクロールするとクラスを追加し、1秒後に解除する
        this.scrolling = true
        setTimeout(this.removeScroll, 1000);
      }
    },
    removeScroll(){
      this.scrolling = false
    }
  }
}
</script>

<style scoped>
  .ly_container{
      padding: 120px 32px;
      background-color: #FFF;
  }
  .ly_formWrapper{
      width: fit-content;
  }
  .bl_breadcrumb+.ly_container{
      padding-top: 117px;
  }
  .bl_breadcrumb+.ly_container table.lists_table thead th{
    top: 98px;
  }
  .isScroll >>> .bl_header_outer_title  {
    box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
  }
  .bl_userHeader+.ly_container,
  .bl_administratorHeader+.ly_container{
      padding-top: 137px;
  }

  .bl_userHeader+.ly_container table.lists_table thead th,
  .bl_administratorHeader+.ly_container table.lists_table thead th{
    top: 137px;
  }
  /* 公開ページと登録ガイド */
  .bl_guide{
    min-width: 800px;
    max-width: 85%;
    margin: 20px 0;
    padding: 0 1rem 0 0;
  }
  .bl_guide p{
    text-align: justify;
  }
  /* 組織とユーザーのブロック */
    .bl_container >>> .bl_organizationBox{
        display: grid;
        grid-template-columns: 220px 1fr;
        width: 100%;
        min-width: 600px;
        border: 1px solid #000;
        margin: 1rem 0;
    }
    .bl_container >>> .bl_organizationBox_grid1{
        grid-column: 1;
        padding: 16px;
    }
    .bl_container >>> .bl_organizationBox_grid1 button{
        width: 180px;
        margin: 0 0 16px;
    }
    .bl_container >>> .bl_organizationBox_grid1 button:last-child{
        margin: 0;
    }
    .bl_container >>> .bl_organizationBox_grid2{
        grid-column: 2;
        padding: 16px;
    }
    .bl_container >>> .bl_organizationBox_grid2 p{
        min-width: 100%;
    }
    .bl_container >>> .bl_organizationBox_grid2 .formrow,
    .bl_container >>> .bl_userBox_grid2 .formrow{
        width: 100%;
        grid-template-columns: 8em 1fr;
    }
    .bl_container >>> .bl_organizationBox_wrapper,
    .bl_container >>> .bl_userBox_wrapper{
        width: 100%;
        min-width: 600px;
        border: 1px solid #CDD6DD;
        padding: 16px;
    }
</style>
