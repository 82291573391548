import Vue from 'vue'
import axios from '@/api'

/**
 * リンク管理モジュール
 * @module store/modules/register/links
 */

// state
const state = {
    links:[],
    total:0,
    page:1,
    link:{},

    // 検索条件
    type:'house',
    categories:[],
    level1:null,
    level2:null,
    categoryCode:null,
    startDate:null,
    endDate:null,
    organizationName:'',
    approved:false,
    categoriesLevels:{},
    dateType:'',
}

const getters = {
    // リンク一覧を返す
    links(state){
        return state.links
    },
    // ページネータ用のデータを返す
    searchStatus(state){
        return {
            total:state.total,
            page:state.page,
            type:state.type,
            level1:state.level1,
            level2:state.level2,
            categoryCode:state.categoryCode,
            startDate:state.startDate,
            endDate:state.endDate,
            organizationName:state.organizationName,
            approved:state.approved,
            dateType:state.dateType,
        }
    },
    level1List(state){
        return Object.keys(state.categoriesLevels)
    },
    level2List(state){
        return Object.keys(state.categoriesLevels[state.level1] ?? {})
    },
    categoriesList(state){
        if(state.level1 === null) {
            return state.categories
        } else if (state.level2 === null) {
            return state.categories.filter(x => {
                return x.level1 === state.level1
            })
        } else {
            return state.categories.filter(x => {
                return x.level1 === state.level1 && x.level2 === state.level2
            })
        }
    },
    // リンクの編集情報を返す
    link(state){
        return state.link
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    getLinks({state, commit}){
        const params = {
            page:state.page,
            type:state.type,
            level1:state.level1,
            level2:state.level2,
            category_code:state.categoryCode,
            organization_name:state.organizationName.trim(),
            approved:state.approved,
            date_type:state.dateType,
            start_date:state.startDate,
            finish_date:state.endDate,
        }
        return axios.get('/api/links', {params})
            .then(response => {
                commit('update', {
                    links:response.data.links,
                    total:response.data.total,
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // 一覧画面用のcategoriesを取得し更新
    updateCategories({state, commit}){
        const params = {
            type:state.type
        }
        return axios.get('/api/links/categories', {params})
        .then(response => {
            // オブジェクトの配列になっていたりオブジェクト入りのオブジェクトになっていたりでレスポンスが一定しないので形を整える
            const categories = Object.keys(response.data.categories).map(key => {
                return response.data.categories[key]
            })

            commit('update', {
                categories,
            })
            return response
        }, reject => {
            return reject.response
        })
    },
    // 一覧画面用のcategoriesLevelsを取得し更新
    updateCategoriesLevels({state, commit}){
        const params = {
            type:state.type
        }
        return axios.get('/api/links/categories/levels', {params})
        .then(response => {
            commit('update', {
                categoriesLevels:response.data.levels,
            })
            return response
        }, reject => {
            return reject.response
        })
    },
    // リンクの承認
    enabled({state}){
        return axios.put('/api/links/' + state.link.id + '/approved/enabled')
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // リンクの承認取消
    disabled({state}){
        return axios.put('/api/links/' + state.link.id + '/approved/disabled')
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // リンクの削除
    delete({state}){
        return axios.delete('/api/links/' + state.link.id)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}