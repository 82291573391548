const routes = [
    {
        path: '/organization/links',
        component: () => import(/* webpackChunkName: "group-organization-links" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-organization-links" */ '@/views/OrganizationLinks.vue'),
            },
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "group-organization-links" */ '@/views/OrganizationLinksCreate.vue'),
            },
            {
                path: 'create/confirm',
                component: () => import(/* webpackChunkName: "group-organization-links" */ '@/views/OrganizationLinksCreateConfirm.vue'),
            },
            {
                path: 'create/complete',
                component: () => import(/* webpackChunkName: "group-organization-links" */ '@/views/OrganizationLinksCreateComplete.vue'),
            },
            {
                path: ':id/edit',
                component: () => import(/* webpackChunkName: "group-organization-links" */ '@/views/OrganizationLinksCreate.vue'),
            },
            {
                path: ':id/edit/confirm',
                component: () => import(/* webpackChunkName: "group-organization-links" */ '@/views/OrganizationLinksCreateConfirm.vue'),
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-organization-links" */ '@/views/OrganizationLinksCreateConfirm.vue'),
            },
        ]
    }
]

export default routes