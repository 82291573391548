const routes = [
    {
        path: '/building',
        component: () => import(/* webpackChunkName: "group-building" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-building" */ '@/views/PublicIndex.vue'),
                meta: { noGuard: true }
            },
            {
                path:'links',
                component: () => import(/* webpackChunkName: "group-building" */ '@/views/PublicLinks.vue'),
                meta: { noGuard: true }
            },
            {
                path:'links/organizations',
                component: () => import(/* webpackChunkName: "group-building" */ '@/views/PublicLinksOrganizations.vue'),
                meta: { noGuard: true }
            },
            {
                path:'links/opening',
                component: () => import(/* webpackChunkName: "group-building" */ '@/views/PublicBuildingOpening.vue'),
                meta: { noGuard: true }
            },
        ]
    }
]

export default routes