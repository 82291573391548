const routes = [
    {
        path: '/organization/solar_water_heaters',
        component: () => import('@/App.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/SolarWaterHeaterList.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization',
                }
            },
            {
                path: 'upload',
                component: () => import('@/views/OrganizationDeviceUpload.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization'
                }
            },
            {
                path: 'upload/confirm',
                component: () => import('@/views/OrganizationDeviceUploadConfirm.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization'
                }
            },
            {
                path: ':id',
                component: () => import('@/views/SolarWaterHeatersDetail.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization',
                    type:'detail',
                }
            },
            {
                path: ':id/update/publish_date',
                component: () => import('@/views/OrganizationDeviceUpdatePublishDate.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization'
                }
            },
            {
                path: ':id/delete',
                component: () => import('@/views/OrganizationDeviceApply.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization'
                }
            },
            {
                path: ':id/status/published',
                component: () => import('@/views/OrganizationDeviceApply.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization'
                }
            },
            {
                path: ':id/status/published/cancel',
                component: () => import('@/views/OrganizationDeviceApply.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization'
                }
            },
            {
                path: ':id/status/canceled',
                component: () => import('@/views/OrganizationDeviceApply.vue'),
                meta: {
                    deviceCategoryKey: 'solarWaterHeaters',
                    routeType: 'organization'
                }
            },
        ]
    }
]

export default routes