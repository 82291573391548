import Vue from 'vue'
import axios from '@/api'

/**
 * ブランド事業者管理モジュール
 * @module store/modules/register/brandNames
 */

// state
const state = {
    brandNames:[],
    total:0,
    page:1,
    brandName:{},
}

const getters = {
    // ブランド事業者一覧を返す
    brandNames(state){
        return state.brandNames
    },
    // ページネータ用のデータを返す
    pageStatus(state){
        return {
            total:state.total,
            page:state.page
        }
    },
    // ブランド名の編集情報を返す
    brandName(state){
        return state.brandName
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // brandNameの更新を呼び出す
    updateBrandName({commit}, values){
        commit('updateBrandName', values)
    },
    // ブランド事業者一覧を取得
    getBrandNames({state, commit}){
        return axios.get('/api/brand_names', {
            params:{
                page:state.page
            }
        })
            .then(response => {
                commit('update', {
                    brandNames:response.data.brand_names,
                    total:response.data.total
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // ブランド名の作成
    create({state}){
        return axios.post('/api/brand_names', {
            name:state.brandName.name,
            organization_id:state.brandName.organization_id,
            note:state.brandName.note
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // ブランド名の更新
    edit({state}){
        return axios.put('/api/brand_names/' + state.brandName.id, {
            name:state.brandName.name,
            organization_id:state.brandName.organization_id,
            note:state.brandName.note
        })
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
    // ブランド名の削除
    delete({state}){
        return axios.delete('/api/brand_names/' + state.brandName.id)
            .then(response => {
                return response
            }, reject => {
                return reject.response
            })
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // brandNameの更新
    updateBrandName(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state.brandName, key, values[key])
        })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}