import Vue from 'vue'
import axios from '@/api'
import { isSet } from '@/util'

/**
 * 事業者編集モジュール
 * @module store/modules/register/organization
 */

// state
const state = {
    //事業者名称
    name:'',
    //郵便番号
    postcode:'',
    //所在地
    address:'',
    //代表電話番号
    tel:'',
    //ZIPファイルのパスワード
    zip_password:'',
    //ZIPファイルのパスワード(確認用)
    zip_password_confirmation:'',
    //所属団体
    belong_to:'0',
    //その他情報
    message:'',
    //住宅or非住宅(1:住宅、2:非住宅)
    type:'',
    //id(編集時に使用)
    id:'',

    //ZIPファイルパスワード変更時の現在のパスワードの入力
    old_zip_password:'',
    //ZIPファイルパスワード変更時の新パスワード
    new_zip_password:'',
    //ZIPファイルパスワード変更時の新パスワードの確認
    new_zip_password_confirmation:'',

    //コジェネ機器管理権限
    cogeneration_manager:false,
    //ハイブリッド給湯機管理権限
    hybrid_water_heater_manager:false,
    //電気ヒートポンプ給湯機管理権限
    electric_heat_pump_manager:false,
    //ソーラーシステム機器管理権限
    solar_system_manager:false,
    //太陽熱温水器管理権限
    solar_water_heater_manager:false,
}

const getters = {
    //stateの一括取得
    getAll(state){
        return state
    },
    //パスワードが確認用のものと一致しているかどうかを返す
    prevalidate(state) {
        return state.zip_password === state.zip_password_confirmation
    },
    //パスワードが確認用のものと一致していない時にエラー文を返す
    prevalidateError(state) {
        let errors = {
            zip_password:[],
        }
        if(state.zip_password !== state.zip_password_confirmation) {
            errors.zip_password.push('ファイルアップロード用パスワードが確認用ファイルアップロード用パスワードと一致しません')
        }
        return errors
    },
    //ZIPファイルの新パスワードが確認と一致しているか返す
    prevalidateZip(state) {
        return isSet(state.new_zip_password) && state.new_zip_password === state.new_zip_password_confirmation
    },
    //ZIPファイルの新パスワードが確認と一致していない時にエラー文を返す
    prevalidateZipError(state) {
        let errors = {
            new_zip_password:[],
        }
        if(isSet(state.new_zip_password) === false){
            errors.new_zip_password.push('新パスワードを入力してください')
        } else if(state.new_zip_password !== state.new_zip_password_confirmation) {
            errors.new_zip_password.push('新パスワードが確認用新パスワードと一致しません')
        }
        return errors
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // backendと通信し、Organizationを更新
    updateOrganization({state}){
        const cogeneration_manager = isSet(state.cogeneration_manager) ? state.cogeneration_manager : null
        const hybrid_water_heater_manager = isSet(state.hybrid_water_heater_manager) ? state.hybrid_water_heater_manager : null
        const electric_heat_pump_manager = isSet(state.electric_heat_pump_manager) ? state.electric_heat_pump_manager : null
        const solar_system_manager = isSet(state.solar_system_manager) ? state.solar_system_manager : null
        const solar_water_heater_manager = isSet(state.solar_water_heater_manager) ? state.solar_water_heater_manager : null

        const params = {
            name: state.name,
            postcode: state.postcode,
            address: state.address,
            tel: state.tel,
            belong_to: state.belong_to,
            message: state.message,
            type: state.type,
            id: state.id,
            cogeneration_manager,
            hybrid_water_heater_manager,
            electric_heat_pump_manager,
            solar_system_manager,
            solar_water_heater_manager,
        }
        return axios.put('/api/organizations/' + state.id, params)
    },
    // backendと通信し、ZIPファイルパスワードを更新
    updateZipPass({state},id){
        const params = {
            old_zip_password : state.old_zip_password,
            new_zip_password : state.new_zip_password,
            new_zip_password_confirmation : state.new_zip_password_confirmation,
        }
        return axios.post('/api/organizations/' + id + '/zip_password', params)
    },
    // ZIPパスワード変更用の入力の初期化
    clearZipPass({commit}){
        commit('clearZipPass')
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // zipパスワード変更用の入力の初期化
    clearZipPass(state) {
        Vue.set(state, 'old_zip_password', '')
        Vue.set(state, 'new_zip_password', '')
        Vue.set(state, 'new_zip_password_confirmation', '')
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}