const routes = [
    {
        path: '/electric_heat_pumps',
        component: () => import(/* webpackChunkName: "group-electric-heat-pumps" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-electric-heat-pumps" */ '@/views/PublicElectricHeatPumps.vue'),
                meta: { noGuard: true }
            },
            {
                path:':id',
                component: () => import(/* webpackChunkName: "group-electric-heat-pumps" */ '@/views/ElectricHeatPumpsDetail.vue'),
                meta: {
                    noGuard: true,
                    type:'detail',
                }
            },
        ]
    }
]

export default routes