/**
 * 管理者モジュール
 * @module store/modules/admin
 */

import organization from '@/store/modules/admin/organization'
import brandNames from '@/store/modules/admin/brandNames'
import links from '@/store/modules/admin/links'
import evaluationOrganizations from '@/store/modules/admin/evaluationOrganizations'
import sharedPasswords from '@/store/modules/admin/sharedPasswords'

//モジュールの登録
const modules = {
    organization,   //事業者管理モジュール
    brandNames,     //ブランド事業者管理モジュール
    links,          //リンク管理モジュール
    evaluationOrganizations, //評価機関管理モジュール
    sharedPasswords, //共有パスワードモジュール
}

export default {
    namespaced: true,
    modules,
}