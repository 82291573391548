<template>
    <div>
        <input
            :type="type"
            :id="textBoxId"
            :name="name"
            :value="value"
            :step="step"
            :disabled="disabled"
            :placeholder="placeholder"
            :min="min"
            @input="input"
            @change="change"
            @keydown.enter="keydown_enter"
        />
        <p v-if="unit">{{unit}}</p>
    </div>
</template>

<script>
export default {
    props:{
        /**
         * input要素のname属性に指定する値. label要素のfor属性にも指定される
         * @type {String}
         */
        name:{
            type: [String, undefined],
            default: undefined
        },
        value:{
            type: [String, Number],
            required: false
        },
        type:{
            type: String,
            required: false,
            default: 'text'
        },
        /**
         * 単位
         * @type {String}
         */
        unit:{
            type: String,
            default: null
        },
        /**
         * 数値入力のステップ指定
         */
        step:{
            type: [String, Number, undefined],
            default: undefined
        },
        /**
         * 非活性化状態の指定
         */
        disabled:{
            type: Boolean,
            default: false
        },
        /**
         * プレースホルダーの指定
         */
        placeholder: {
            type: [String, undefined],
            default: undefined
        },
        /**
         * 数値入力時の最小値
         */
        min: {
            type: [Number, String, undefined],
            default: undefined
        },
        /**
         * input要素に指定するid属性
         */
        textBoxId: {
            type: [String, undefined],
            default: undefined
        }
    },
    methods:{
        input(event){
            this.$emit('input', event.target.value)
        },
        change(event){
            this.$emit('change', event.target.value)
        },
        keydown_enter(event){
            this.$emit('keydown_enter', event)
        }
    }
}
</script>

<style lang="scss" scoped>
    div{
        display:inline-flex;
        align-items:center;
    }
    input {
        box-sizing: border-box;
        margin:0;
        padding: 2px 8px;
        line-height: 20px;
        font-size: 14px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
    input[type="text"],
    input[type="mail"]{
        width: 12em;
    }
    input[type="number"]{
        width: 7em;
        text-align: right;
    }
    .url input{
        width: 100%;
        min-width: 22em;
        max-width: 40em;
    }
    input[type="date"]{
        min-width: 150px;
    }
    p{
        display: inline-block;
        margin: 0;
        padding-left:0.5rem;
    }
    /* クラスでスタイルをあてるもの */
    .wd100per input{
        width: 100%;
    }
    /* IDでスタイルがあたるもの */
    #house_name {
        width:36em;
    }


/*
--------------------------------------------
レスポンシブ
--------------------------------------------
 */

 /* タブレット縦よりウインドウ幅が狭いもの */
@media screen and (max-width: 1000px) {
    #house_name {
        width: 100%;
    }
}

</style>