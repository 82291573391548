import Encoding from 'encoding-japanese'
import { cloneDeep } from 'lodash'

/**
 * 多数のコンポーネント、moduleで共通して使用することが見込まれる関数を
 * 一か所で管理するためのJSファイル
 */

/**
 * 遷移時の操作（NavigationDuplicateErrorの処理を含む）
 * @param {VueRouter} router
 * @param {String} path
 */
export function move (router, path) {
    router.push(path).catch(() => {})
}

export const pageContentCount = 25

/**
 * Storeから取得してきたデータのうちKeysに含まれるものをsetterつきのcomputedデータとして扱えるよう変換する
 * @param {Array} keys
 */
export function mapComputedProperties(keys) {
    const map = []
    keys.forEach(key => {
        map[key] = {
            get(){
                return this.data[key]
            },
            set(newValue){
                this.update({
                    [key]:newValue
                })
            }
        }
    })
    return map
}

/**
 * Storeから取得してきたデータのうちKeysに含まれるものをsetterつきのcomputedデータとして扱えるよう変換する
 * @param {Array} keys
 */
export function mapComputedArrayProperties(keys) {
    const map = []
    keys.forEach(key => {
        map[key] = {
            get(){
                return this.data[key]
            },
            set(newValue){
                const newArray = cloneDeep(this.data[key])
                const isNewValue = (element) => element == newValue
                const index = newArray.findIndex(isNewValue)
                if (index >= 0) {
                    newArray.splice(index, 1)
                } else {
                    newArray.splice(newArray.length, 0, newValue)
                }
                this.update({
                    [key]:newArray
                })
            }
        }
    })
    return map
}

/**
 * レスポンスからCSVデータをダウンロードさせる
 * @param {Object} response 
 */
export function getCSVFile(response) {
    /** ファイル名称をレスポンスヘッダから抜き出す
     レスポンスヘッダからcontent-dispositionを取得 */
    const disposition = Encoding.stringToCode(response.headers['content-disposition'])

    // content-dispositionの文字コードをUNICODEに変換
    const convertedDisposition = Encoding.convert(disposition, "UNICODE", Encoding.detect(disposition))

    // UNICODEに変換したcontent-dispositionを文字列に変換
    const stringDisposition = Encoding.codeToString(convertedDisposition)

    // ファイル名称を抜き出すための正規表現を作成
    const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/

    // ファイル名称の抜出を実行
    const filename = decodeURI(filenameRegex.exec(stringDisposition)[1].replace(/['"]/g, '').replace('utf-8',''))

    // 文字コードを統一しておく
    const str_array = Encoding.stringToCode(response.data)
    const sjis_array = Encoding.convert(str_array, "SJIS", "UNICODE")
    const uint8_array = new Uint8Array(sjis_array)

    // ダウンロード用のBlobを作成
    let blob = new Blob([uint8_array], {type: 'text/csv'})
    if(navigator.msSaveOrOpenBlob){
        // IE対応(EdgeもmsSaveOrOpenBlobに対応しているためこちらで処理されます)
        navigator.msSaveOrOpenBlob(blob, filename)
    } else {
        // a要素の作成
        let link = document.createElement('a')
        // ダウンロード対象にBlobを指定
        link.href = window.URL.createObjectURL(blob)
        // ファイル名称を指定
        link.download = filename
        // 実在する要素(非表示でよい)の子要素としてa要素を追加しないとChrome以外で動かない
        document.getElementById('invisible').appendChild(link)
        link.click()
        document.getElementById('invisible').removeChild(link)
    }
}

export function isSet(value){
    return !(value === null || value === undefined || value === '')
}

export function isSetNumber(value){
    return isSet(value) && value !== '-' ? ' ' + value : ''
}

export function isSetString(value){
    return isSet(value) ? value : '-' 
}

export const defaultValue = {
    user:{
        division:'',
        name:'',
        tel:'',
        email:'',
        email_confirmation:'',
        password:'',
        password_confirmation:'',
    },
    organization:{
        name:'',
        postcode:'',
        address:'',
        tel:'',
        zip_password:'',
        zip_password_confirmation:'',
        belong_to:'0',
        message:'',
        type:'',
        cogeneration_manager:false,
        hybrid_water_heater_manager:false,
        electric_heat_pump_manager:false,
        solar_system_manager:false,
        solar_water_heater_manager:false,
    }
}
