const routes = [
    {
        path: '/admin/links',
        component: () => import(/* webpackChunkName: "group-admin-links" */ '@/App.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-admin-links" */ '@/views/AdminLinks.vue'),
            },
            {
                path: ':id/approved/enabled',
                component: () => import(/* webpackChunkName: "group-admin-links" */ '@/views/AdminLinksConfirm.vue'),
                meta:{type:'enabled'}
            },
            {
                path: ':id/approved/disabled',
                component: () => import(/* webpackChunkName: "group-admin-links" */ '@/views/AdminLinksConfirm.vue'),
                meta:{type:'disabled'}
            },
            {
                path: ':id/delete',
                component: () => import(/* webpackChunkName: "group-admin-links" */ '@/views/AdminLinksConfirm.vue'),
                meta:{type:'delete'}
            },
        ]
    }
]

export default routes