const routes = [
    {
        path: '/organization/organization',
        component: () => import(/* webpackChunkName: "group-organization-organization" */ '@/App.vue'),
        children: [
            {
                path:'',
                component: () => import(/* webpackChunkName: "group-organization-organization" */ '@/views/OrganizationsDetail.vue'),
            },
            {
                path:'edit',
                component: () => import(/* webpackChunkName: "group-organization-organization" */ '@/views/OrganizationEdit.vue'),
            },
            {
                path:'edit/confirm',
                component: () => import(/* webpackChunkName: "group-organization-organization" */ '@/views/OrganizationEditConfirm.vue'),
            },
            {
                path:'zip_password/edit',
                component: () => import(/* webpackChunkName: "group-organization-organization" */ '@/views/OrganizationFilePassEdit.vue'),
            },
        ]
    }
]

export default routes